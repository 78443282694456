import React from 'react';

import { DonationFundPlatformTypeEnum, RegistryOrderFragment } from '@graphql/generated';
import { IconV2, TextV2 } from '@withjoy/joykit';
import { Info } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';
import { useFeatureValue } from '@shared/core/featureFlags';

import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { AddExternalOrderTrackingDialog } from '@apps/registry/common/components/AddExternalOrderTracking';

import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';
import { usePurchaseConfirmationProvider } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.provider';
import { ModalStates } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.controller';
import { ToastVariableProps } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';
import { useCheckoutDialogContext } from '@apps/registry/guest/components/CheckoutDialog';

import { StyleReceiptBox } from './Receipts.styles';
import { ReservedOrder, ElsewherePurchasedOrder, JoyPurchasedOrderWrapper } from './components';

type ProductList = Readonly<{
  counts: {
    everything: number;
    favorites: number;
    stillNeeded: number;
    purchased: number;
    reserved: number;
    over100: number;
    under100: number;
    available: number;
    deliverable: number;
  };
  products: readonly CookedProduct[];
}>;

interface ReceiptsSectionProps
  extends Readonly<{
    eventId: string;
    helpDialogOpen: boolean;
    isAddExternalOrderTrackingDialogOpen: boolean;
    productList: ProductList;
    reservedOrderList: RegistryOrderFragment[];
    purchasedOrderList: RegistryOrderFragment[];
    cartOrderCount: number;
    orderForTrackingRequest: Maybe<RegistryOrderFragment>;
    paths: ReturnType<typeof useRoutePaths>;
    openToast?: (toast: ToastVariableProps) => void;
    showHelpDialog: () => void;
    handleDialogClose: () => void;
    closeAddExternalOrderTrackingDialog: () => void;
  }> {}

export const ReceiptsSection: React.FC<ReceiptsSectionProps> = ({
  eventId,
  isAddExternalOrderTrackingDialogOpen,
  productList,
  reservedOrderList,
  purchasedOrderList,
  cartOrderCount,
  orderForTrackingRequest,
  paths,
  openToast,
  closeAddExternalOrderTrackingDialog
}) => {
  const { t } = useTranslation('sharedRegistry');
  const { openModal } = usePurchaseConfirmationProvider();
  const { openCheckoutDialog } = useCheckoutDialogContext();

  const { isPurchased, purchasedElsewhere, purchasedFromJoy } = t('shoppingCart');
  const enableCashFundCheckoutV2 = useFeatureValue('enableCashFundCheckoutV2');

  const [joyPurchasedOrderList, elsewherePurchasedOrderList] = purchasedOrderList.reduce(
    (acc, orderList) => {
      if (orderList.isDropship) return [[...acc[0], orderList], acc[1]];
      else return [acc[0], [...acc[1], orderList]];
    },
    [[], []] as Array<Array<RegistryOrderFragment>>
  );

  return (
    <>
      {cartOrderCount > 0 && (
        <>
          {reservedOrderList?.length > 0 && (
            <StyleReceiptBox paddingX={5}>
              <TextV2 typographyVariant="hed2" display="flex" alignItems="center" justifyContent="center" paddingY={6}>
                {isPurchased()}
                <IconV2 size="sm" fill="mono14" marginLeft={1}>
                  <Info />
                </IconV2>
              </TextV2>
              {reservedOrderList.map(
                order =>
                  order && (
                    <ReservedOrder
                      key={`reserved-${order.id}`}
                      paths={paths}
                      orderLineItems={order.lineItems}
                      productList={productList.products}
                      eventId={eventId}
                      order={order}
                      openModal={(productId: string, modalStateToBeOpened?: ModalStates) => {
                        // Manual added items and Transferred registry items are not supported yet on the new flow, so here we avoid that case
                        if (enableCashFundCheckoutV2) {
                          const platformType = productList.products.find(product => product.id === productId)?.donationFund?.fund.platform?.type;
                          const isDonation =
                            platformType === DonationFundPlatformTypeEnum.paypal ||
                            platformType === DonationFundPlatformTypeEnum.other ||
                            platformType === DonationFundPlatformTypeEnum.venmo ||
                            platformType === DonationFundPlatformTypeEnum.cashapp;
                          const isOrderTypeSupportedOnV2 =
                            platformType === DonationFundPlatformTypeEnum.other ||
                            platformType === DonationFundPlatformTypeEnum.venmo ||
                            platformType === DonationFundPlatformTypeEnum.cashapp ||
                            isDonation;

                          if (isOrderTypeSupportedOnV2) {
                            openCheckoutDialog({ registryItemId: productId, currentOrderId: order.id });
                            return;
                          }
                        }

                        openModal({
                          orderId: order.id,
                          productId,
                          modalStateToBeOpened
                        });
                      }}
                    />
                  )
              )}
            </StyleReceiptBox>
          )}
          {joyPurchasedOrderList?.length > 0 && (
            <StyleReceiptBox paddingX={5}>
              <TextV2 typographyVariant="hed2" display="flex" alignItems="center" justifyContent="center" paddingY={6}>
                {purchasedFromJoy()}
              </TextV2>
              {joyPurchasedOrderList.map(order => order && <JoyPurchasedOrderWrapper key={`joy-purchased-${order.id}`} order={order} products={productList.products} />)}
            </StyleReceiptBox>
          )}
          {elsewherePurchasedOrderList?.length > 0 && (
            <StyleReceiptBox paddingX={5}>
              <TextV2 typographyVariant="hed2" display="flex" alignItems="center" justifyContent="center" paddingY={6}>
                {purchasedElsewhere()}
              </TextV2>
              {elsewherePurchasedOrderList.map(
                order =>
                  order && (
                    <ElsewherePurchasedOrder
                      paths={paths}
                      orderId={order.id}
                      isDropship={order.isDropship}
                      orderLineItems={order.lineItems}
                      key={`elsewhere-purchased-${order.id}`}
                      productList={productList.products}
                      eventId={eventId}
                      note={order.note}
                      fulfillment={order.fulfillment}
                    />
                  )
              )}
            </StyleReceiptBox>
          )}
          <AddExternalOrderTrackingDialog
            openToast={openToast}
            onClose={closeAddExternalOrderTrackingDialog}
            isOpen={isAddExternalOrderTrackingDialogOpen}
            orderId={orderForTrackingRequest?.id || ''}
            orderQuantity={orderForTrackingRequest?.lineItems[0]?.quantity.toString() || '1'}
            orderTitle={orderForTrackingRequest?.lineItems[0]?.frozenProductData.title}
            orderPhotoUrl={orderForTrackingRequest?.lineItems[0]?.frozenProductData.photos[0]?.url}
            orderNumber={orderForTrackingRequest?.fulfillment?.orderNumber}
            trackingNumber={orderForTrackingRequest?.fulfillment?.shipments?.[0]?.trackingInformation?.trackingNumber}
            email={orderForTrackingRequest?.email || ''}
            isPurchased={orderForTrackingRequest?.isPurchased || false}
          />
        </>
      )}
    </>
  );
};
