import React from 'react';
import type { RoutePath } from '@shared/core/router.helper/useRouterHelper';
import { Redirect } from '@shared/core/router.helper/reactRouter';
import loadable from '@loadable/component';
import { RoutesProvider } from '@shared/core';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';
import { CREATE_BABY_REGISTRY_APP_NAME } from '@apps/createBabyRegistry/constants';
import { SERVICE_CENTER_APP_NAME } from '@apps/serviceCenter/constants';
import { EventRoute } from './EventRoute';
import { getEcardType } from '@apps/saveTheDate/SaveTheDate.utils';
import EcardError from '@shared/components/EmailsAndEcards/components/Error';
import { SAVE_THE_DATE_PHOTO_ECARD_TYPE } from '@apps/saveTheDate/constants';

const Admin = loadable(
  () =>
    import(
      /* webpackChunkName: "content/admin" */
      '@apps/admin'
    )
);

const Guest = loadable(
  () =>
    import(
      /* webpackChunkName: "content/guest" */
      '@apps/guest'
    )
);

const AccountApp = loadable(
  () =>
    import(
      /* webpackChunkName: "content/account" */
      '@apps/account'
    )
);

const PublicShop = loadable(
  () =>
    import(
      /* webpackChunkName: "content/publicshop" */
      '@apps/registry/public/routes/Shop/Shop'
    )
);

const AccountMe = loadable(
  () =>
    import(
      /* webpackChunkName: "content/account/me" */
      '@apps/accountMe'
    )
);

const CreateWedding = loadable(
  () =>
    import(
      /* webpackChunkName: "content/createwedding" */
      '@apps/createWedding'
    )
);

const CreateBabyRegistry = loadable(
  () =>
    import(
      /* webpackChunkName: "content/createbabyregistry" */
      '@apps/createBabyRegistry'
    )
);

const ShopComponent = () => {
  return (
    <RoutesProvider>
      <PublicShop />
    </RoutesProvider>
  );
};

const ExamplesSimpleShop = loadable(
  () =>
    import(
      /* webpackChunkName: "content/examples/registry-shop-manage" */
      '@apps/examples/registry-shop-manage'
    )
);

const EcardGuest = loadable(
  () =>
    import(
      /* webpackChunkName: "content/ecard/guest" */
      '@apps/ecard/guest'
    )
);

const SaveTheDateGuest = loadable(
  () =>
    import(
      /* webpackChunkName: "content/saveTheDate/guest" */
      '@apps/saveTheDate/guest'
    )
);

const ServiceCenter = loadable(
  () =>
    import(
      /* webpackChunkName: "content/servicecenter" */
      '@apps/serviceCenter'
    )
);

const ContactCollectorGuest = loadable(
  () =>
    import(
      /* webpackChunkName: "content/contactCollector/guest" */
      '@apps/contactCollector/guest'
    )
);

const ContactCollectorGuestWrapper = ({ magicLinkId }: { magicLinkId: string }) => {
  return (
    <RoutesProvider>
      <ContactCollectorGuest magicLinkId={magicLinkId} />
    </RoutesProvider>
  );
};

const RegistryGuest = loadable(
  () =>
    import(
      /* webpackChunkName: "content/registry-guest" */
      '@apps/registry/guest'
    )
);

const PageNotFound = loadable(() => import(/* webpackChunkName: "apps-pageNotFound-ssr" */ '@apps/pageNotFound/ssr'));

export const ssrRoutes: Array<RoutePath<{ eventHandle: string; magicLinkId: string }>> = [
  {
    path: '/404',
    component: () => <PageNotFound />
  },
  {
    path: '/contact/:magicLinkId',
    component: ({ match }) => (match ? <ContactCollectorGuestWrapper magicLinkId={match.params.magicLinkId} /> : null)
  },
  {
    path: '/account/(events|personal-info)',
    component: () => <AccountMe appUrl={'account'} />
  },
  {
    path: '/account',
    component: () => <AccountApp appUrl="account" />
  },
  {
    path: '/shipping-policy',
    // This route will only render if the feature flag is provided
    component: () => <ShopComponent />
  },
  {
    path: '/return-policy',
    // This route will only render if the feature flag is provided
    component: () => <ShopComponent />
  },
  {
    path: '/shop',
    // This route will only render if the feature flag is provided
    component: () => <ShopComponent />
  },
  {
    path: `/${CREATE_WEDDING_APP_NAME}`,
    component: () => <CreateWedding />
  },
  {
    path: `/${CREATE_BABY_REGISTRY_APP_NAME}`,
    component: () => <CreateBabyRegistry />
  },
  {
    path: '/examples/reg-shop-manage',
    component: () => (__DEV__ ? <ExamplesSimpleShop /> : <Redirect to="/" />)
  },
  {
    path: '/ecard',
    component: ({ location }) => {
      const ecardType = getEcardType(location);
      return ecardType === undefined ? <EcardError /> : ecardType === SAVE_THE_DATE_PHOTO_ECARD_TYPE ? <SaveTheDateGuest /> : <EcardGuest />;
    }
  },
  {
    path: `/${SERVICE_CENTER_APP_NAME}`,
    component: () => <ServiceCenter />
  },
  {
    path: '/:eventHandle/edit',
    component: ({ match }) => <EventRoute eventHandle={match.params.eventHandle} ComponentToRender={Admin} />
  },
  {
    path: '/:eventHandle/registry',
    component: ({ match }) => <EventRoute eventHandle={match.params.eventHandle} ComponentToRender={RegistryGuest} />
  },
  {
    path: '/:eventHandle',
    component: ({ match }) => (
      <EventRoute eventHandle={match.params.eventHandle}>
        <Guest />
      </EventRoute>
    )
  }
];
