// @ts-nocheck
import { createTelemetryObject, createTelemetry, PagePrefix } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { useMemo } from 'react';
import { useRouteMatch } from '@react-router';

const ADMIN_REGISTRY_PAGE_PREFIX: PagePrefix = 'admin.registry';
const GUEST_REGISTRY_PAGE_PREFIX: PagePrefix = 'guest';
const GIFT_WRAP_SUB_CATEGORY = 'giftwrap';

interface ShoppingCartTelemetryExtraInfo {
  eventId?: string | null;
  section?: string;
}

export enum GiftWrapType {
  BUY_NOW = 'BuyNow',
  CART = 'Cart',
  AFFILIATE = 'Affiliate',
  CASH_FUND = 'CashFund',
  RSVP = 'Rsvp'
}

type ReserveJoyRegistryProps = {
  eventId?: string | null;
  productId?: string;
  registryItemId?: string;
  registryId?: string | null;
  reservedQty: number;
  priceValueInMinorUnits?: number;
  priceCurrencyCode?: string;
  destinationUrl?: string | null;
  typeOfItem?: string;
  buttonLabel: string;
  productTitle?: string;
  checkoutMechanism?: string;
  giftGiverName?: string;
  giftGiverEmail?: string;
  generatedLabel?: string | null;
  isGroupGiftingEnabled?: boolean;
};

interface ShoppingCartItems {
  productTitle?: string;
  price?: string;
}

interface ShoppingCartLogProps {
  items: ShoppingCartItems[];
  isShown: boolean;
}

interface ShoppingCartUpdateProps {
  productTitle: string;
  registryItemId: string;
  registryId?: string | null;
  quantity: number;
  eventId?: string;
  price?: number;
}

export type ShoppingCartCheckoutFlowProps = {
  eventId?: string;
  itemListCount: number;
  cartTotalValueInMinorUnits: number;
};

type PagesForTelemetry = {
  registry: 'guestRegistry';
  purchase: 'purchase';
};

const pageTracking: { [key in keyof PagesForTelemetry]: PageFunctionType } = {
  registry: (...args) =>
    createObjectWithPagePrefix(
      {
        page: 'Registry',
        action: 'ViewRegistry'
      },
      args
    ),
  purchase: (...args) =>
    createObjectWithPagePrefix(
      {
        page: 'Purchase',
        action: 'ViewPurchase'
      },
      args
    )
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createObjectWithPagePrefix = (target: any, source: any[]) => {
  if (typeof source[source.length - 1] === 'object') {
    return Object.assign(target, ...source);
  }

  return target;
};

export const shoppingCartTelemetry = createTelemetryObject({
  actions: {
    ShoppingCartPurchaseClick: (
      {
        eventId,
        productId,
        registryItemId,
        registryId,
        reservedQty,
        priceValueInMinorUnits,
        priceCurrencyCode,
        destinationUrl,
        typeOfItem,
        buttonLabel,
        productTitle,
        isGroupGiftingEnabled
      }: ReserveJoyRegistryProps,
      ...args
    ) =>
      createObjectWithPagePrefix(
        {
          action: 'PurchaseJoyRegistryItem',
          actionType: 'click',
          label: productTitle,
          extraInfo: {
            eventId,
            productId,
            registryItemId,
            registryId,
            reservedQty,
            priceValueInMinorUnits,
            priceCurrencyCode,
            destinationUrl,
            typeOfItem,
            buttonLabel,
            isGroupGiftingEnabled
          }
        },
        args
      ),
    shoppingCartMakePurchaseClick: (
      {
        eventId,
        productId,
        registryItemId,
        registryId,
        reservedQty,
        priceValueInMinorUnits,
        priceCurrencyCode,
        destinationUrl,
        typeOfItem,
        buttonLabel,
        productTitle,
        giftGiverName,
        giftGiverEmail,
        isGroupGiftingEnabled
      }: ReserveJoyRegistryProps,
      ...args
    ) =>
      createObjectWithPagePrefix(
        {
          action: 'MarkJoyRegistryItemAsPurchased',
          label: buttonLabel,
          extraInfo: {
            eventId,
            productId,
            registryItemId,
            registryId,
            reservedQty,
            priceValueInMinorUnits,
            priceCurrencyCode,
            destinationUrl,
            typeOfItem,
            buttonLabel,
            productTitle,
            giftGiverName,
            giftGiverEmail,
            isGroupGiftingEnabled
          }
        },
        args
      ),
    ShoppingCartCancelReservationClick: (
      {
        eventId,
        productId,
        registryItemId,
        registryId,
        reservedQty,
        priceValueInMinorUnits,
        priceCurrencyCode,
        destinationUrl,
        typeOfItem,
        buttonLabel,
        productTitle,
        isGroupGiftingEnabled
      }: ReserveJoyRegistryProps,
      ...args
    ) =>
      createObjectWithPagePrefix(
        {
          action: 'CancelReservationClick',
          label: productTitle,
          extraInfo: {
            eventId,
            productId,
            registryItemId,
            registryId,
            reservedQty,
            priceValueInMinorUnits,
            priceCurrencyCode,
            destinationUrl,
            typeOfItem,
            buttonLabel,
            isGroupGiftingEnabled
          }
        },
        args
      ),
    ShoppingCartDidntPurchaseClick: (
      {
        eventId,
        productId,
        registryItemId,
        registryId,
        reservedQty,
        priceValueInMinorUnits,
        priceCurrencyCode,
        destinationUrl,
        typeOfItem,
        buttonLabel,
        productTitle,
        isGroupGiftingEnabled
      }: ReserveJoyRegistryProps,
      ...args
    ) =>
      createObjectWithPagePrefix(
        {
          action: 'DidntPurchaseJoyRegistryItem',
          actionType: 'click',
          label: productTitle,
          extraInfo: {
            eventId,
            productId,
            registryItemId,
            registryId,
            reservedQty,
            priceValueInMinorUnits,
            priceCurrencyCode,
            destinationUrl,
            typeOfItem,
            buttonLabel,
            isGroupGiftingEnabled
          }
        },
        args
      ),
    purchaseConfirmationResponded: (yesButtonClicked: boolean, { productTitle, registryItemId, registryId, isGroupGiftingEnabled }: RegistryItemClickProps, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'PurchaseConfirmationResponded',
          label: yesButtonClicked ? 'Yes' : 'No',
          extraInfo: {
            productTitle,
            registryItemId,
            registryId,
            isGroupGiftingEnabled
          }
        },
        args
      ),
    buyRegistryItem: (
      { productTitle, action, method, reservedQty, registryItemId, priceValueInMinorUnits, priceCurrencyCode, itemType, buttonLabel, redirectUrl }: BuyRegistryItemProps,
      ...args
    ) =>
      createObjectWithPagePrefix(
        {
          action: 'PurchaseJoyRegistryItem',
          actionType: 'click',
          label: productTitle,
          extraInfo: {
            action,
            redirectUrl,
            method,
            registryItemId,
            reservedQty,
            priceValueInMinorUnits,
            priceCurrencyCode,
            itemType,
            buttonLabel
          }
        },
        args
      ),
    ShoppingCartRemoveItemClick: (
      {
        eventId,
        productId,
        registryItemId,
        registryId,
        reservedQty,
        priceValueInMinorUnits,
        priceCurrencyCode,
        destinationUrl,
        typeOfItem,
        buttonLabel,
        productTitle,
        isGroupGiftingEnabled
      }: ReserveJoyRegistryProps,
      ...args
    ) =>
      createObjectWithPagePrefix(
        {
          action: 'RemoveItemFromCart',
          actionType: 'click',
          label: productTitle,
          extraInfo: {
            eventId,
            productId,
            registryItemId,
            registryId,
            reservedQty,
            priceValueInMinorUnits,
            priceCurrencyCode,
            destinationUrl,
            typeOfItem,
            buttonLabel,
            isGroupGiftingEnabled
          }
        },
        args
      ),
    logShoppingCartLoad: ({ isShown, items }: ShoppingCartLogProps, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'ShoppingCartShown',
          label: String(isShown),
          extraInfo: {
            items
          }
        },
        args
      ),
    giftMessageSaveClicked: (...args) => createObjectWithPagePrefix({ action: 'GiftMessageSave', actionType: 'click' }, args),
    buyNowClickedGuest: ({ productId, checkoutUrl, title }: { productId: string; checkoutUrl: string; title: string; isGroupGiftingEnabled?: boolean }, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'BuyNowClickedGuest',
          actionType: 'click',
          extraInfo: { productId, checkoutUrl, title }
        },
        args
      ),
    trackingInfoAddOnShoppingCart: (...args) =>
      createObjectWithPagePrefix(
        {
          action: 'TrackingInfoAdd',
          actionType: 'click',
          label: 'ShoppingCart'
        },
        args
      ),
    trackingInfoSubmitOnShoppingCart: (...args) =>
      createObjectWithPagePrefix(
        {
          action: 'TrackingInfoSubmit',
          actionType: 'click',
          label: 'ShoppingCart'
        },
        args
      ),
    shoppingCartButtonClick: ({ eventId, device }: { eventId: string; device: 'mobileOrTablet' | 'desktop' }, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'ShoppingCartButton',
          actionType: 'click',
          externalInfo: {
            eventId,
            device
          }
        },
        args
      ),
    shoppingCartEditCartClick: ({ productTitle, registryItemId, registryId, quantity, eventId, price }: ShoppingCartUpdateProps, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'RegistryItemEditCart',
          actionType: 'click',
          label: 'Shopping cart V2',
          extraInfo: {
            registryItemId,
            quantity,
            productTitle: productTitle,
            total: price ? price * quantity : null,
            eventId,
            registryId
          }
        },
        args
      ),
    shoppingCartRemoveFromCartClick: ({ productTitle, registryItemId, registryId, quantity, eventId, price }: ShoppingCartUpdateProps, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'RegistryItemRemoveFromCart',
          actionType: 'click',
          label: 'Shopping cart V2',
          extraInfo: {
            registryItemId,
            quantity,
            productTitle: productTitle,
            total: price ? price * quantity : null,
            eventId,
            registryId
          }
        },
        args
      ),
    shoppingCartCheckoutClick: (extraInfo: ShoppingCartCheckoutFlowProps, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'RegistryItemCartCheckout',
          actionType: 'click',
          label: 'Shopping cart V2',
          extraInfo
        },
        args
      ),
    shoppingCartAddShippingClick: (extraInfo: ShoppingCartCheckoutFlowProps, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'RegistryItemCartShipping',
          actionType: 'click',
          label: 'Shopping cart V2',
          extraInfo
        },
        args
      ),
    shoppingCartAddDetailsClick: (extraInfo: ShoppingCartCheckoutFlowProps, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'RegistryItemCartDetails',
          actionType: 'click',
          label: 'Shopping cart V2',
          extraInfo
        },
        args
      ),
    shoppingCartConfirmOrder: (extraInfo: { eventId?: string; itemsTotal?: string; shippingFee?: string; taxes?: string; orderTotal?: string; orderId?: string }, ...args) =>
      createObjectWithPagePrefix(
        {
          action: 'RegistryItemSalesOrder',
          label: 'Shopping cart V2',
          extraInfo
        },
        args
      ),
    transactionCompleted: (
      {
        checkoutType,
        salesOrderId,
        giftName,
        hasGiftWrap,
        price
      }: {
        checkoutType: string;
        salesOrderId: string;
        giftName: string;
        hasGiftWrap: boolean;
        price?: string;
      },
      ...args
    ) =>
      createObjectWithPagePrefix(
        {
          action: 'TransactionCompleted',
          label: checkoutType,
          extraInfo: {
            salesOrderId,
            giftName,
            hasGiftWrap,
            price
          }
        },
        args
      ),
    giftWrapOfferViewed: ({ eventHandle, type, defaultDesign, designOrder }: { eventHandle: string; type: GiftWrapType; defaultDesign: string; designOrder: string }, ...args) =>
      createObjectWithPagePrefix(
        {
          subCategory: GIFT_WRAP_SUB_CATEGORY,
          action: 'GiftWrapOfferViewed',
          label: eventHandle,
          extraInfo: {
            checkoutType: type,
            defaultDesign,
            designOrder
          }
        },
        args
      ),
    giftWrapPromptSent: ({ prompt }: { prompt: string }, ...args) =>
      createObjectWithPagePrefix(
        {
          subCategory: GIFT_WRAP_SUB_CATEGORY,
          action: 'PromptSentToAI',
          label: 'giftwrap',
          extraInfo: {
            prompt
          }
        },
        args
      ),
    giftWrapMessageEdited: ({ originalNote, modifiedNote }: { originalNote: string; modifiedNote: string }, ...args) =>
      createObjectWithPagePrefix(
        {
          subCategory: GIFT_WRAP_SUB_CATEGORY,
          action: 'AIDraftEdited',
          label: 'giftwrap',
          extraInfo: {
            originalNote,
            modifiedNote
          }
        },
        args
      ),
    giftWrapDraftSaveError: ({ thrownError, draft }: { thrownError: unknown; draft: object }, ...args) =>
      createObjectWithPagePrefix(
        {
          subCategory: GIFT_WRAP_SUB_CATEGORY,
          action: 'EmailDraftSaveError',
          label: 'giftwrap',
          extraInfo: {
            thrownError,
            draft
          }
        },
        args
      ),
    addCardGiftWrapClicked: (
      {
        design,
        giftName,
        giftType,
        message,
        price,
        defaultDesign,
        designOrder,
        designsPreviewed
      }: {
        design: string;
        eventHandle: string;
        giftName: string;
        giftType: string;
        message: string;
        price: string;
        defaultDesign: string;
        designOrder: string;
        designsPreviewed: string;
      },
      ...args
    ) =>
      createObjectWithPagePrefix(
        {
          subCategory: GIFT_WRAP_SUB_CATEGORY,
          action: 'AddGiftWrap',
          label: design,
          actionType: 'click',
          extraInfo: {
            design,
            giftName,
            giftType,
            message,
            price,
            defaultDesign,
            designOrder,
            designsPreviewed
          }
        },
        args
      ),
    viewMoreDesignsClicked: (show: boolean, ...args) =>
      createObjectWithPagePrefix(
        {
          subCategory: GIFT_WRAP_SUB_CATEGORY,
          action: 'ButtonInteracted',
          label: 'ViewMoreDesigns',
          actionType: 'click',
          extraInfo: {
            show
          }
        },
        args
      ),
    uneditedWarningShown: (...args) =>
      createObjectWithPagePrefix(
        {
          subCategory: GIFT_WRAP_SUB_CATEGORY,
          action: 'giftwrapUneditedWarning'
        },
        args
      ),
    giftWrapEditMessageClicked: ({ label }: { label: string }, ...args) =>
      createObjectWithPagePrefix(
        {
          subCategory: GIFT_WRAP_SUB_CATEGORY,
          action: 'ButtonInteracted',
          label
        },
        args
      )
  },
  pages: pageTracking
});

const { TelemetryProvider, useTelemetry } = createTelemetry(shoppingCartTelemetry, { eventId: '', section: '' } as ShoppingCartTelemetryExtraInfo);
const useShoppingCartTelemetry = (...args: Parameters<typeof useTelemetry>) => {
  const registryRouteMatch = useRouteMatch<{ eventHandle: string; edit?: string; subRoute: string }>('/:eventHandle/:edit?/registry/:subRoute?');
  const telem = useTelemetry(...args);

  const analyticOverwrite = useMemo(() => {
    const pagePrefix = registryRouteMatch?.params.edit ? ADMIN_REGISTRY_PAGE_PREFIX : GUEST_REGISTRY_PAGE_PREFIX;
    const categorySuffix = registryRouteMatch?.params.subRoute?.replace('/', '.');
    const category = categorySuffix ? `${pagePrefix}.${categorySuffix}` : pagePrefix;
    return { category, pagePrefix };
  }, [registryRouteMatch?.params.edit, registryRouteMatch?.params.subRoute]);

  return useMemo(() => {
    const { updateContext, context, ...analyticFnsMap } = telem;
    const updatedTelemObject = { updateContext, context } as typeof analyticFnsMap & { updateContext: typeof updateContext; context: typeof context };
    Object.keys(analyticFnsMap).forEach(key => {
      if (typeof analyticFnsMap[key] === 'object') {
        updatedTelemObject[key] = {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updatedTelemObject[key]['enter'] = (...args: any[]) => analyticFnsMap[key]['enter'](...args, analyticOverwrite);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updatedTelemObject[key]['exit'] = (...args: any[]) => analyticFnsMap[key]['exit'](...args, analyticOverwrite);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updatedTelemObject[key] = (...args: any[]) => analyticFnsMap[key](...args, analyticOverwrite);
      }
    });
    return updatedTelemObject;
  }, [telem, analyticOverwrite]);
};

export { TelemetryProvider as ShoppingCartTelemetryProvider, useShoppingCartTelemetry };
export { ReserveJoyRegistryProps };
