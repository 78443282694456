import React from 'react';

import { Box, IconV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { WarningTriangle } from '@withjoy/joykit/icons';

import { styles } from './AddressAtoms.style';
import { useTranslation } from '@shared/core';

export const WarningAddressAtom = () => {
  const { t2 } = useTranslation('sharedRegistry');
  const { missingCoupleAddress, contactTheCouple } = t2('shoppingCart', 'addressAtoms');
  return (
    <Box __css={styles.addressAtomWarningWrapperStyles}>
      <IconV2 size="sm" color="negative6" alignSelf="flex-start" marginRight={5}>
        <WarningTriangle />
      </IconV2>
      <SpacingStack spacing={1}>
        <TextV2 typographyVariant="hed1">{missingCoupleAddress}</TextV2>
        <TextV2 typographyVariant="label2" color="mono12">
          {contactTheCouple}
        </TextV2>
      </SpacingStack>
    </Box>
  );
};
