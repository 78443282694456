import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { ApolloError } from '@apollo/client';
import { CookedProduct, isPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';

const category = 'guest.registry';
const GIFT_WRAP_CATEGORY = 'guest.registry.giftwrap';

type PagesForTelemetry = {
  registry: 'registry';
  purchase: 'purchase';
  contribute: 'contribute';
  cancelConfirm: 'cancelConfirm';
  welcome: 'welcome';
};

export enum GiftWrapType {
  BUY_NOW = 'BuyNow',
  CART = 'Cart',
  AFFILIATE = 'Affiliate',
  CASH_FUND = 'CashFund',
  RSVP = 'Rsvp'
}

const pageTracking: { [key in keyof PagesForTelemetry]: PageFunctionType } = {
  registry: () => ({
    category: 'registry',
    pagePrefix: 'guest',
    page: 'Registry',
    action: 'ViewRegistry'
  }),
  purchase: () => ({
    category: 'registry',
    pagePrefix: 'guest',
    page: 'Purchase',
    action: 'ViewPurchase'
  }),
  contribute: () => ({
    category: 'registry',
    pagePrefix: 'guest',
    page: 'Contribute',
    action: 'ViewContribute'
  }),
  cancelConfirm: () => ({
    category: 'registry',
    pagePrefix: 'guest',
    page: 'CancelConfirm',
    action: 'ViewCancelConfirm'
  }),
  welcome: () => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'Welcome',
    action: 'ViewGiftsClick'
  })
};

interface RegistryItemClickProps {
  productTitle: string;
  registryItemId: string;
  registryId?: string | null;
  registryItemState?: string;
  generatedLabel?: string | null;
  isGroupGiftingEnabled?: boolean;
  ourMostWanted?: boolean;
}

type RegistryItemBuyNowClickedProps = Omit<RegistryItemClickProps, 'registryItemState'> & { isGroupGiftingEnabled?: boolean };

interface RegistryViewGiftsClickProps {
  clickFrom: string;
}
interface ReservedRegistryItemClickProps {
  productTitle: string;
  registryItemId: string;
  registryId?: string | null;
  isEmailMatched: boolean;
}

type ReserveJoyRegistryProps = {
  eventId?: string | null;
  productId?: string;
  registryItemId?: string;
  registryId?: string | null;
  reservedQty: number;
  priceValueInMinorUnits?: number;
  priceCurrencyCode?: string;
  destinationUrl?: string | null;
  typeOfItem?: string;
  buttonLabel: string;
  productTitle?: string;
  checkoutMechanism?: string;
  giftGiverName?: string;
  giftGiverEmail?: string;
  generatedLabel?: string | null;
  isGroupGiftingEnabled?: boolean;
};

interface UndoReserveItemProps {
  productTitle?: string;
  registryItemId?: string;
  registryId?: string | null;
  method: string;
  reservedQty: number;
  priceValueInMinorUnits?: number;
  priceCurrencyCode?: string;
  itemType?: string;
  isGroupGiftingEnabled?: boolean;
}

interface externalRegistryProps {
  id: string;
  typeOfItem?: string;
  reservedQty?: number;
  destinationUrl?: string;
  priceCurrencyCode?: string;
  priceValueInMinorUnits: number;
}

interface ShoppingCartUpdateProps {
  productTitle: string;
  registryItemId: string;
  registryId?: string | null;
  quantity: number;
  eventId?: string;
  price?: number;
}

export type ShoppingCartCheckoutFlowProps = {
  eventId?: string;
  itemListCount: number;
  cartTotalValueInMinorUnits: number;
};

export const guestRegistryTelemetry = createTelemetryObject({
  actions: {
    guestReadMore: () => ({
      category,
      action: 'GuestReadMore',
      actionType: 'click'
    }),
    externalRegistry: ({ id, reservedQty, priceValueInMinorUnits, priceCurrencyCode, destinationUrl, typeOfItem }: externalRegistryProps) => ({
      category,
      action: 'ExternalRegistry',
      actionType: 'click',
      extraInfo: {
        id,
        reservedQty,
        priceValueInMinorUnits,
        priceCurrencyCode,
        destinationUrl,
        typeOfItem
      }
    }),
    registryItem: ({
      productTitle,
      registryItemId,
      registryId,
      registryItemState = 'RegistryItem',
      generatedLabel,
      isGroupGiftingEnabled,
      ourMostWanted
    }: RegistryItemClickProps) => ({
      category,
      action: registryItemState,
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        registryItemId,
        registryId,
        generatedLabel,
        isGroupGiftingEnabled,
        ourMostWanted
      }
    }),
    clickToBuy: (product: CookedProduct, buttonLabel: string, eventId?: string) => ({
      category,
      action: 'BuyLinkedRegistryItem',
      actionType: 'click',
      label: product.title,
      extraInfo: {
        price: isPricedProduct(product)
          ? {
              currencyCode: product.currencyCode,
              valueInMinorUnits: product.valueInMinorUnits
            }
          : {},
        productId: product.id,
        registryItemId: product.registryItemId,
        totalRequested: product.requested,
        alreadyPurchased: product.purchased,
        stillNeeded: product.stillNeeded,
        registryId: product.registry.id,
        destinationUrl: product.checkoutUrl || product.registry.url,
        typeOfItem: product.registryItemType,
        buttonLabel: buttonLabel,
        eventId
      }
    }),
    createRegistry: (eventId: string, id: string) => ({
      category,
      action: 'CreateRegistry',
      actionType: 'click',
      extraInfo: { eventId, id }
    }),
    editPageNote: (eventId: string) => ({
      category,
      action: 'EditPageNote',
      actionType: 'click',
      extraInfo: { eventId }
    }),
    registryNoteSet: (note: string) => ({
      category,
      action: 'RegistryNoteSet',
      actionType: 'click',
      page: 'manage',
      pagePrefix: 'admin',
      label: note
    }),
    registryDashboardButton: (website: string) => ({
      category,
      action: 'RegistryDashboardButton',
      actionType: 'click',
      extraInfo: { website }
    }),
    registryOpenFilePicker: () => ({
      category,
      action: 'OpenRegistryPhotoFilePicker',
      actionType: 'click'
    }),
    registryPagePhotoUpdated: (action: 'Delete' | 'Update') => ({
      category,
      action: 'PagePhotoUpdated',
      label: action,
      extraInfo: {
        pageId: 'registry'
      }
    }),
    trackError: (mutation: string, error: ApolloError, extra = {}) => ({
      category,
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { error, mutation, ...extra }
    }),
    giveNow: ({ giftAmount, productTitle, eventId, registryItemId, donationFundId, externalUrl, flowVariant }) => ({
      category,
      action: 'CashGiveNow',
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        giftAmount,
        eventId,
        registryItemId,
        donationFundId,
        externalUrl,
        flowVariant
      }
    }),
    affiliateBuyNow: ({
      productId,
      productTitle,
      eventId,
      registryItemId,
      registryId,
      externalUrl,
      checkoutMechanism,
      priceValueInMinorUnits,
      buttonLabel,
      reservedQty
    }: {
      eventId: string;
      registryItemId: string;
      externalUrl?: string;
      registryId: string;
      checkoutMechanism: string;
      priceValueInMinorUnits: number;
      buttonLabel: string;
      reservedQty: number;
      productId?: string;
      productTitle?: string;
    }) => ({
      category,
      action: 'AffiliateBuyNow',
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        eventId,
        registryItemId,
        externalUrl,
        productId,
        registryId,
        checkoutMechanism,
        priceValueInMinorUnits,
        buttonLabel,
        reservedQty
      }
    }),
    addToCart: ({ giftAmount, productTitle, eventId, registryItemId, donationFundId, externalUrl, flowVariant }) => ({
      category,
      action: 'CashAddToCart',
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        giftAmount,
        eventId,
        registryItemId,
        donationFundId,
        externalUrl,
        flowVariant
      }
    }),
    addGifterDetails: ({ gifterEmail, gifterName, gifterNote, giftAmount, productTitle, eventId, registryItemId, donationFundId, externalUrl, flowVariant }) => ({
      category,
      action: 'CashAddGifterDetails',
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        gifterEmail,
        gifterName,
        gifterNote,
        giftAmount,
        eventId,
        registryItemId,
        donationFundId,
        externalUrl,
        flowVariant
      }
    }),
    affiliateAddGifterDetails: ({ gifterEmail, gifterName, gifterNote, giftAmount, giftQuantity, productTitle, productId, eventId, registryItemId, externalUrl }) => ({
      category,
      action: 'AffiliateAddGifterDetails',
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        productId,
        gifterEmail,
        gifterName,
        gifterNote,
        giftAmount,
        giftQuantity,
        eventId,
        registryItemId,
        externalUrl
      }
    }),
    selectPayment: ({ paymentMethod, gifterEmail, gifterName, gifterNote, giftAmount, productTitle, eventId, registryItemId, donationFundId, externalUrl, flowVariant }) => ({
      category,
      action: 'CashSelectPayment',
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        paymentMethod,
        gifterEmail,
        gifterName,
        gifterNote,
        giftAmount,
        eventId,
        registryItemId,
        donationFundId,
        externalUrl,
        flowVariant
      }
    }),
    reserveAndPurchase: ({
      eventId,
      productId,
      registryItemId,
      registryId,
      reservedQty,
      priceValueInMinorUnits,
      priceCurrencyCode,
      destinationUrl,
      typeOfItem,
      buttonLabel,
      productTitle,
      checkoutMechanism = 'externalNavigation',
      giftGiverName,
      giftGiverEmail,
      generatedLabel,
      isGroupGiftingEnabled
    }: ReserveJoyRegistryProps) => ({
      category,
      action: 'ReserveJoyRegistryItem',
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        eventId,
        productId,
        registryItemId,
        registryId,
        reservedQty,
        priceValueInMinorUnits,
        priceCurrencyCode,
        destinationUrl,
        typeOfItem,
        buttonLabel,
        checkoutMechanism,
        giftGiverName,
        giftGiverEmail,
        generatedLabel,
        isGroupGiftingEnabled
      }
    }),
    undoReserveItem: ({
      productTitle,
      registryItemId,
      registryId,
      method,
      reservedQty,
      priceValueInMinorUnits,
      priceCurrencyCode,
      itemType,
      isGroupGiftingEnabled
    }: UndoReserveItemProps) => ({
      category,
      action: 'RemoveItemFromCart',
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        registryItemId,
        method,
        registryId,
        reservedQty,
        priceValueInMinorUnits,
        priceCurrencyCode,
        itemType,
        isGroupGiftingEnabled
      }
    }),
    reservedRegistryItem: ({ productTitle, registryItemId, registryId, isEmailMatched }: ReservedRegistryItemClickProps) => ({
      category,
      action: isEmailMatched ? 'ReservedRegistryItem-EmailMatched' : 'ReservedRegistryItem-EmailNotMatched',
      label: productTitle,
      extraInfo: {
        registryItemId,
        registryId
      }
    }),
    ViewGiftsClick: ({ clickFrom }: RegistryViewGiftsClickProps) => ({
      category: 'wedding',
      action: 'ViewGiftsClick',
      extraInfo: {
        clickFrom
      }
    }),
    viewItemDetailsClick: ({ productTitle, registryItemId, registryId, isGroupGiftingEnabled }: RegistryItemClickProps) => ({
      category,
      action: 'ViewItemInStoreClick',
      label: productTitle,
      extraInfo: {
        registryItemId,
        registryId,
        isGroupGiftingEnabled
      }
    }),
    purchaseConfirmationDisplayed: ({ productTitle, registryItemId, registryId, isGroupGiftingEnabled }: RegistryItemClickProps) => ({
      category,
      action: 'PurchaseConfirmationDisplayed',
      label: productTitle,
      extraInfo: {
        registryItemId,
        registryId,
        isGroupGiftingEnabled
      }
    }),
    authenticatedSession: (emailId: string, action: string) => ({
      category,
      action: action,
      extraInfo: { emailId }
    }),
    sortByOptionClickedByGuest: ({ sortOption }: { sortOption?: string }) => ({
      category,
      action: 'SortByOptionClickedByGuest',
      actionType: 'click',
      extraInfo: { sortOption }
    }),
    registryItemBuyNowClicked: ({ productTitle, registryItemId, registryId, isGroupGiftingEnabled }: RegistryItemBuyNowClickedProps) => ({
      category,
      action: 'RegistryItemBuyNow',
      actionType: 'click',
      label: productTitle,
      extraInfo: {
        registryItemId,
        registryId,
        isGroupGiftingEnabled
      }
    }),
    orderTrackingInfoSubmit: () => ({
      category: 'admin.registry.manage',
      action: 'TrackingInfoSubmit',
      actionType: 'click',
      label: 'Gift tracking'
    }),
    addOrderTracking: (orderNumber?: string | null) => ({
      category: 'guest.registry',
      action: 'TrackingInfoSubmit',
      actionType: 'click',
      label: 'PurchasePage',
      extraInfo: {
        orderNumber
      }
    }),
    cancelOrderTracking: (giftName?: string) => ({
      category: 'guest.registry',
      action: 'CancelReservation',
      actionType: 'click',
      label: giftName
    }),
    groupGiftPartialContribution: ({ productTitle, registryItemId, registryId, contributionAmount }: RegistryItemBuyNowClickedProps & { contributionAmount: number }) => ({
      category: 'guest.registry',
      action: 'GroupGiftPartialContribution',
      actionType: 'click',
      label: productTitle,
      externalInfo: {
        registryItemId,
        registryId,
        contributionAmount
      }
    }),
    groupGiftRemainingContribution: ({ productTitle, registryItemId, registryId, contributionAmount }: RegistryItemBuyNowClickedProps & { contributionAmount: number }) => ({
      category: 'guest.registry',
      action: 'GroupGiftRemainingContribution',
      actionType: 'click',
      label: productTitle,
      externalInfo: {
        registryItemId,
        registryId,
        contributionAmount
      }
    }),
    shoppingCartAddToCartClick: ({ productTitle, registryItemId, registryId, quantity, eventId, price }: ShoppingCartUpdateProps) => ({
      category,
      action: 'RegistryItemAddToCart',
      actionType: 'click',
      label: 'Shopping cart V2',
      extraInfo: {
        registryItemId,
        quantity,
        productTitle: productTitle,
        total: price ? price * quantity : null,
        eventId,
        registryId
      }
    }),
    noThanksGiftWrapClicked: ({ eventHandle }: { eventHandle: string }) => ({
      category: GIFT_WRAP_CATEGORY,
      action: 'NoThanks',
      label: eventHandle,
      actionType: 'click'
    }),
    upgradeGiftNoteGiftWrapClicked: ({ eventHandle }: { eventHandle: string }) => ({
      category: GIFT_WRAP_CATEGORY,
      action: 'UpgradeGiftNote',
      label: eventHandle,
      actionType: 'click'
    }),
    ecardPurchaseConfirmationViewed: ({
      label,
      pcId,
      giftName,
      eCardPrice,
      price
    }: {
      label: 'cash' | 'affiliate';
      pcId: string;
      giftName: string;
      eCardPrice: number;
      price: number;
    }) => ({
      category: 'guest.registry',
      action: 'PurchaseConfirmationViewed',
      label,
      extraInfo: {
        pcId,
        giftName,
        hasGiftWrap: true,
        eCardPrice,
        price
      }
    }),
    giftWrapAddressCheck: ({ allow, label, registryAddress, eventLocation }: { allow: boolean; label: string; registryAddress: unknown; eventLocation: unknown }) => ({
      category: GIFT_WRAP_CATEGORY,
      action: 'GiftwrapAddressCheck',
      label,
      extraInfo: {
        allow,
        registryAddress,
        eventLocation
      }
    }),
    alreadyReservedGiftViewed: ({ label }: { label: string }) => ({
      category,
      action: 'AlreadyReservedGift',
      label
    })
  },
  pages: pageTracking
});

const { TelemetryProvider, useTelemetry } = createTelemetry(guestRegistryTelemetry, { eventId: '', section: '' });
export { TelemetryProvider, useTelemetry as useGuestRegistryTelemetry };
export { ReserveJoyRegistryProps };
