import React from 'react';
import { Flex, Box, TextV2, CloseButton } from '@withjoy/joykit';
import { CartIcon } from '@assets/icons';
import { StyledHederBox, StyleText } from '../../ShoppingCart.styles';
import { useTranslation } from '@shared/core';
import { HeaderProps } from '../../ShoppingCart.types';

export const Header: React.FC<HeaderProps> = ({ giftCount, cartTotal, onClose }) => {
  const { t } = useTranslation('sharedRegistry');
  const { estimatedTotal } = t('shoppingCart');
  const { cartGiftCount, cartGiftCounts, cartEmptyTitle, cartTitle } = t('shoppingCart');
  const giftCountText = giftCount > 1 ? cartGiftCounts({ count: giftCount }) : cartGiftCount({ count: giftCount });
  return (
    <StyledHederBox margin="2px">
      <Box paddingY={24}>
        <Flex marginX={16}>
          <StyleText typographyVariant="hed5" alignItems="center" display="inline-flex">
            <CartIcon /> {giftCount ? cartTitle() : cartEmptyTitle()}
          </StyleText>
          {!!onClose && (
            <CloseButton
              position="absolute"
              top={0}
              right={0}
              paddingLeft={0}
              paddingRight={0}
              onClick={ev => {
                ev.stopPropagation();
                onClose({ closeSource: 'closeButton', event: ev });
              }}
            />
          )}
        </Flex>
        {giftCount ? (
          <Flex alignItems="center" justifyContent="space-between" paddingX={6} marginTop={6} color="mono12">
            <TextV2 typographyVariant="hed3">{giftCountText}</TextV2>
            <TextV2 typographyVariant="hed3">
              {estimatedTotal()} {cartTotal}
            </TextV2>
          </Flex>
        ) : null}
      </Box>
    </StyledHederBox>
  );
};
