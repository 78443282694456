import { LayoutShell } from '@apps/registry/guest/components/CheckoutDialog/components';
import { useGiftWrapECardPrice } from '@shared/components/GiftWrap';
import { useTranslation } from '@shared/core';

import { useEventInfo } from '@shared/utils/eventInfo';
import { GiftWrapType } from '@apps/registry/guest/GuestRegistry.telemetry';
import React, { useEffect, useRef } from 'react';
import { useCheckoutFunnel, useCheckoutInitDataContext } from '../../hooks';
import GiftWrapOffer from '../../components/GiftWrapOffer';
import { ButtonV2 } from '@withjoy/joykit';
import { useShoppingCartTelemetry } from '@apps/registry/common/components/ShoppingCart/ShoppingCart.telemetry';

const GiftWrap = () => {
  const {
    giftWrapOfferViewed,
    giftWrapPromptSent,
    giftWrapMessageEdited,
    addCardGiftWrapClicked,
    giftWrapEditMessageClicked,
    viewMoreDesignsClicked,
    uneditedWarningShown
  } = useShoppingCartTelemetry();

  const { registryItem } = useCheckoutInitDataContext('giftWrap');
  const { addGiftWrap, submitGiftNote, formValues } = useCheckoutFunnel(({ addGiftWrap, formValues, submitGiftNote }) => [addGiftWrap, formValues, submitGiftNote]);
  const { eventHandle } = useEventInfo();
  const registryGiftWrapEcardPrice = useGiftWrapECardPrice();

  const { t } = useTranslation('guestRegistry');
  const tGiftCard = t('checkoutGiftCard');
  const PRICE = `$${registryGiftWrapEcardPrice}`;
  const defaultThemeIDRef = useRef<string>('');
  const themeHistoryRef = useRef<string[]>([]);
  const themeOrderRef = useRef<string[]>([]);
  const offerViewedSent = useRef(false);

  useEffect(() => {
    if (offerViewedSent.current !== true && defaultThemeIDRef.current && themeOrderRef.current.length > 0) {
      giftWrapOfferViewed({
        eventHandle: eventHandle as string,
        type: registryItem.donationFund?.id ? GiftWrapType.CASH_FUND : GiftWrapType.AFFILIATE,
        defaultDesign: defaultThemeIDRef.current ?? 'none',
        designOrder: themeOrderRef.current.toString()
      });
      offerViewedSent.current = true;
    }
  }, [giftWrapOfferViewed, eventHandle, registryItem.donationFund?.id]);

  const handleAddGiftWrap = (message: string, themeId: string) => {
    let productLink: string | undefined;
    try {
      const origin = window?.location?.origin ?? 'https://withjoy.com';
      productLink = new URL(`${eventHandle}/edit/registry/track`, origin)?.href;
    } catch (e) {}
    addGiftWrap({
      savedGiftWrapMessage: message,
      note: JSON.stringify({
        message,
        themeId,
        image: registryItem.productData?.photos?.[0]?.url || undefined,
        title: registryItem.productData.title,
        store: registryItem.productData.brand || '',
        giftLink: productLink
      })
    });
    addCardGiftWrapClicked({
      eventHandle: eventHandle as string,
      design: themeId ?? 'none',
      giftName: registryItem.productData.title,
      giftType: registryItem.productData.customType || 'giftType',
      message,
      price: PRICE,
      defaultDesign: defaultThemeIDRef.current ?? 'none',
      designsPreviewed: themeHistoryRef.current.toString(),
      designOrder: themeOrderRef.current.toString()
    });
  };

  const handleOnAddGiftNote = (message: string) => {
    submitGiftNote({ note: message });
  };

  const handleSetThemeId = (themeId: string, defaultThemeID: string, themeHistory: string[], themeOrder: string[]) => {
    defaultThemeIDRef.current = defaultThemeID;
    themeHistoryRef.current = themeHistory;
    themeOrderRef.current = themeOrder;
  };

  return (
    <LayoutShell>
      <LayoutShell.Header />
      <LayoutShell.Body display="flex" flexDirection="column" alignItems="center" paddingX={'1.5rem'} paddingBottom={'1.5rem'}>
        <GiftWrapOffer
          initialMessage={formValues.savedGiftWrapMessage}
          product={{
            image: registryItem.productData?.photos?.[0]?.url || undefined,
            title: registryItem.productData.title,
            store: registryItem.productData.brand || '',
            platform: formValues?.paymentMethod
          }}
          giftWrapUneditedMessageShown={uneditedWarningShown}
          giftWrapEditMessageClicked={giftWrapEditMessageClicked}
          giftWrapMessageEdited={giftWrapMessageEdited}
          giftWrapPromptSent={giftWrapPromptSent}
          viewMoreDesignsClicked={viewMoreDesignsClicked}
          onSetThemeId={handleSetThemeId}
          giftName={registryItem.productData.title}
          giverName={formValues.name || ''}
          skip={!!formValues.savedGiftWrapMessage}
          onButtonClick={({ currentTab, message, themeId }) => (currentTab === 'wrap' ? handleAddGiftWrap(message, themeId) : handleOnAddGiftNote(message))}
        >
          {({ onClick }) => (
            <ButtonV2 alignSelf="center" intent="neutral" shape="rounded" minWidth="20rem" marginTop="1.5rem !important" size="lg" onClick={onClick}>
              {tGiftCard.next()}
            </ButtonV2>
          )}
        </GiftWrapOffer>
      </LayoutShell.Body>
    </LayoutShell>
  );
};

export default GiftWrap;
