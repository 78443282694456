import React from 'react';
import { DialogV2, TextV2, Box } from '@withjoy/joykit';
import { ReactComponent as ReservIcon } from '@assets/icons/card-check.svg';
import { useTranslation } from '@shared/core';
import { StyledHelpBox, StyledHelpDialog } from './ShoppingCart.styles';
import { ReactComponent as CartIcon } from '@assets/icons/cart-icon.svg';
import { ReactComponent as InfoIcon } from '@assets/icons/info-icon.svg';
import { ReactComponent as CancelIcon } from '@assets/icons/cancel-icon.svg';
import { ReactComponent as PurchasedIcon } from '@assets/icons/registry-synced-check.svg';
import { LaunchEmailSupportLink } from '@apps/registry/guest/components';

interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
  }> {}

export const HelpDialog: React.FC<Props> = props => {
  const { isOpen, onClose } = props;
  const { t2 } = useTranslation('sharedRegistry');
  const {
    reservingGifts,
    reservingGiftsDescription,
    purchasingGifts,
    purchasingGiftsDescription,
    cancellingDescription,
    markPurchased,
    markPurchasedDescription,
    pastPurchases,
    pastPurchasesDescription,
    cancelling,
    helpTitle,
    stillHelp,
    contactSupport
  } = t2('shoppingCartHelp');

  return (
    <StyledHelpDialog id="HelpDialog" onClose={onClose} isOpen={isOpen} size={'sm'}>
      <DialogV2.Header>
        <TextV2 typographyVariant="hed4">{helpTitle}</TextV2>
      </DialogV2.Header>
      <DialogV2.Body>
        <StyledHelpBox>
          <TextV2 typographyVariant="hed1" display="flex" alignItems="center">
            <ReservIcon />
            {reservingGifts}
          </TextV2>
          <TextV2 typographyVariant="body1" display="flex" alignItems="center">
            {reservingGiftsDescription}
          </TextV2>
        </StyledHelpBox>
        <StyledHelpBox>
          <TextV2 typographyVariant="hed1" display="flex" alignItems="center">
            <CartIcon />
            {purchasingGifts}
          </TextV2>
          <TextV2 typographyVariant="body1" display="flex" alignItems="center">
            {purchasingGiftsDescription}
          </TextV2>
        </StyledHelpBox>
        <StyledHelpBox>
          <TextV2 display="flex" typographyVariant="hed1" alignItems="center">
            <PurchasedIcon />
            {markPurchased}
          </TextV2>
          <TextV2 typographyVariant="body1" display="flex" alignItems="center">
            {markPurchasedDescription}
          </TextV2>
        </StyledHelpBox>
        <StyledHelpBox>
          <TextV2 display="flex" typographyVariant="hed1" alignItems="center">
            <InfoIcon />
            {pastPurchases}
          </TextV2>
          <TextV2 typographyVariant="body1" display="flex" alignItems="center">
            {pastPurchasesDescription}
          </TextV2>
        </StyledHelpBox>
        <StyledHelpBox>
          <TextV2 display="flex" typographyVariant="hed1" alignItems="center">
            <CancelIcon />
            {cancelling}
          </TextV2>
          <TextV2 typographyVariant="body1" display="flex" alignItems="center">
            {cancellingDescription}
          </TextV2>
        </StyledHelpBox>
        <StyledHelpBox display="flex" alignItems="center">
          <TextV2 display="flex" typographyVariant="hed1" alignItems="center">
            {stillHelp}
          </TextV2>
          <Box marginLeft={2}>
            <LaunchEmailSupportLink>
              <TextV2 color={'green6'}>{contactSupport}</TextV2>
            </LaunchEmailSupportLink>
          </Box>
        </StyledHelpBox>
      </DialogV2.Body>
    </StyledHelpDialog>
  );
};
