import React from 'react';
import { StyledGiftNoteCard, StyledGiftNoteWrapper, StyledInputError, StyledEditNoteWrapper, StyledEditButton, StyledAddNoteButton, styles } from './GiftNote.styles';
import { ButtonV2, TextV2, Flex, TextArea } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useGiftNoteController } from './GiftNote.controller';
import { ReactComponent as GiftNoteIcon } from '@assets/icons/gift-note.svg';

export interface GiftNoteProps {
  note: Maybe<string>;
  orderId: string;
}

export const GiftNote: React.FC<GiftNoteProps> = ({ note, orderId }) => {
  const { t } = useTranslation('sharedRegistry');
  const { giftMessage, editGiftNote, fieldNotePlaceholder, saveNote, addGiftNote } = t('shoppingCart');

  const { isInEditMode, formik, handleOnClickEdit, handleOnClickSave, loading, giftNoteNotificationText, giftNoteNotificationIsVisible, giftNoteError } = useGiftNoteController(
    note,
    orderId
  );
  const currentNote = formik.values.note;

  if (currentNote || isInEditMode) {
    return (
      <>
        <StyledGiftNoteWrapper __css={styles.giftNoteWrapper}>
          <Flex marginBottom={3} justifyContent="space-between">
            <TextV2 typographyVariant="label3" htmlFor="note" as="label">
              {giftMessage()}
            </TextV2>
            {giftNoteNotificationIsVisible && (
              <TextV2 typographyVariant="label3" htmlFor="note" as="label" color={giftNoteError ? 'negative7' : 'positive7'}>
                {giftNoteNotificationText}
              </TextV2>
            )}
          </Flex>
          {isInEditMode ? (
            <StyledEditNoteWrapper __css={styles.editNoteWrapper}>
              <TextArea {...formik.getFieldProps('note')} placeholder={fieldNotePlaceholder()} resize={false} minRows={3} maxRows={3} tabIndex={0} />
              <StyledInputError className="inputError" __css={styles.inputError} typographyVariant="label2">
                {formik.errors.note && formik.touched.note && formik.errors.note}
              </StyledInputError>
              <ButtonV2 intent="productive" variant="outline" onClick={handleOnClickSave} loading={loading}>
                {saveNote()}
              </ButtonV2>
            </StyledEditNoteWrapper>
          ) : (
            <>
              <StyledGiftNoteCard __css={styles.giftNoteCard}>
                <TextV2 typographyVariant="label2" marginBottom={5}>
                  {currentNote}
                </TextV2>
                <Flex justifyContent="flex-end">
                  <StyledEditButton typographyVariant="body1" intent="productive" variant="link" onClick={handleOnClickEdit}>
                    {editGiftNote()}
                  </StyledEditButton>
                </Flex>
              </StyledGiftNoteCard>
            </>
          )}
        </StyledGiftNoteWrapper>
      </>
    );
  }
  return (
    <StyledGiftNoteWrapper __css={styles.giftNoteWrapper}>
      <StyledAddNoteButton startIcon={<GiftNoteIcon />} variant="ghost" intent="productive" onClick={handleOnClickEdit}>
        {addGiftNote()}
      </StyledAddNoteButton>
    </StyledGiftNoteWrapper>
  );
};
