import React, { useMemo } from 'react';
import { useTranslation } from '@shared/core';
import { ButtonV2 } from '@withjoy/joykit';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useShoppingCart } from '../../state';
import { CartCheckoutSteps } from '../../ShoppingCart.types';
import { useShoppingCartTelemetry } from '../../ShoppingCart.telemetry';

type CartFooterSubmitButtonProps = {
  saveData?: () => Promise<void> | void | boolean;
  disabled?: boolean;
};

export const CartFooterSubmitButton = ({ saveData, disabled }: CartFooterSubmitButtonProps) => {
  const { eventInfo } = useEventInfo();
  const { t } = useTranslation('sharedRegistry');
  const trans = t('shoppingCart');
  const telemetry = useShoppingCartTelemetry();
  const {
    state: { currentCheckoutStep, itemList, itemListCount, checkoutStepsFlow },
    mutators: { updateCurrentCheckoutStep }
  } = useShoppingCart();
  const telemArgs = {
    eventId: eventInfo?.eventId,
    itemListCount,
    cartTotalValueInMinorUnits: itemList.reduce((prev, curr) => curr.priceInMinorUnits * curr.quantity + prev, 0)
  };
  const stepPropsMap = useMemo(
    () =>
      new Map([
        [CartCheckoutSteps.Cart, { onClick: telemetry.shoppingCartCheckoutClick }],
        [CartCheckoutSteps.Shipping, { prevStepButtonText: trans.checkOutButton, onClick: telemetry.shoppingCartAddShippingClick }],
        [CartCheckoutSteps.Details, { prevStepButtonText: trans.continueToDetailsButton, onClick: telemetry.shoppingCartAddDetailsClick, saveData: true }],
        [CartCheckoutSteps.Message, { prevStepButtonText: trans.continueToMessageButton, onClick: telemetry.shoppingCartAddDetailsClick, saveData: true }],
        [CartCheckoutSteps.Payment, { prevStepButtonText: trans.continueToPaymentButton }]
      ]),
    [trans, telemetry]
  );
  const stepsFlowMap = useMemo(() => {
    return checkoutStepsFlow.reduce((acc, step, index, steps) => {
      acc.set(step, steps.at(index + 1) ?? null);
      return acc;
    }, new Map<CartCheckoutSteps, CartCheckoutSteps | null>());
  }, [checkoutStepsFlow]);

  const getSubmitButtonText = () => {
    const nextStep = stepsFlowMap.get(currentCheckoutStep) ?? CartCheckoutSteps.Cart;
    return stepPropsMap.get(nextStep)?.prevStepButtonText?.() ?? trans.checkOutButton();
  };

  const handleCheckoutClick = async () => {
    const nextStep = stepsFlowMap.get(currentCheckoutStep);
    const stepProps = stepPropsMap.get(currentCheckoutStep);
    if (!nextStep || !stepProps) {
      return;
    }
    if (stepProps.saveData && saveData) {
      const shouldContinue = await saveData();
      if (shouldContinue === false) return;
    }
    stepProps.onClick?.(telemArgs);
    updateCurrentCheckoutStep(nextStep);
  };

  return (
    <ButtonV2 intent="neutral" marginTop={6} fullWidth onClick={handleCheckoutClick} disabled={itemList.length === 0 || disabled}>
      {getSubmitButtonText()}
    </ButtonV2>
  );
};
