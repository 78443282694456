import { useTranslation } from '@shared/core';
import { Modal, TextV2, ButtonV2 } from '@withjoy/joykit';
import React from 'react';

interface UneditedMessageWarningProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  isRsvp?: boolean;
}

const UneditedMessageWarning: React.FC<UneditedMessageWarningProps> = ({ isOpen, onClose, onContinue, isRsvp = false }) => {
  const { t } = useTranslation('sharedRegistry');
  const tShoppingCart = t('shoppingCart');
  const { t: tRsvp } = useTranslation('thanksForRSVP');
  const tCongratulationsCard = tRsvp('congratulationsCard');
  const tWarning = tShoppingCart.uneditedMessageWarning;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <Modal.Content>
        <Modal.Header>
          <TextV2 typographyVariant="hed4">{tWarning.title()}</TextV2>
        </Modal.Header>
        <Modal.Body>{isRsvp ? tCongratulationsCard.uneditedWarningMessage() : tWarning.message()}</Modal.Body>
        <Modal.Footer>
          <ButtonV2 variant="ghost" intent="neutral" onClick={onClose}>
            {tWarning.back()}
          </ButtonV2>
          <ButtonV2 intent="neutral" onClick={onContinue}>
            {tWarning.continue()}
          </ButtonV2>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default UneditedMessageWarning;
