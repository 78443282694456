import React, { useEffect, useCallback, useMemo } from 'react';
import { TextV2, Box, LinkV2, OnCloseArg } from '@withjoy/joykit';
import { TempSpinner } from '@shared/components/TempSpinner';
import { StyledBox, StylePurchaseBox, StyledBoxMob, HelpText, StyledCheckoutButton } from './ShoppingCart.styles';
import { Header, ReservedItem, PurchaseHistory, AddressCollapsible, ShoppingCartOpenButton } from './components';
import { CookedProduct, CookedProductList } from '@apps/registry/common/selectors/ProductListSelector';
import { useShoppingCartController } from './ShoppingCart.controller';
import { DonationFundPlatformTypeEnum, LineItemFragment, RegistryOrderFragment } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { ReactComponent as CartIcon } from '@assets/icons/cart-icon.svg';
import { ReactComponent as HelpIcon } from '@assets/icons/icon-help-default.svg';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';
import { ShoppingCartDialog } from './ShoppingCartDialog';
import { HelpDialog } from './HelpDialog';
import { RegistryOrderList, RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { ShippingAddressFragment } from '@graphql/aliases';
import { usePurchaseConfirmationProvider } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.provider';
import { ModalStates } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.controller';
import { withWindow } from '@shared/utils/withWindow';
import { useHistory } from '@react-router';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useCheckoutDialogContext } from '@apps/registry/guest/components/CheckoutDialog';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { pxToRem } from '@withjoy/joykit/theme';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useShoppingCart } from '@apps/registry/common/components/ShoppingCart/state';
import { useMount } from '@shared/utils/hooks/useMount';
import { useShoppingCartTelemetry } from './ShoppingCart.telemetry';

interface ReservedOrderProps
  extends Readonly<{
    productList: CookedProductList['products'];
    orderLineItems: Array<LineItemFragment>;
    eventId: string;
    paths: ReturnType<typeof useRoutePaths>;
    order: RegistryOrderFragment;
    openModal: (productId: string, modalStateToBeOpened?: ModalStates) => void;
  }> {}

const ReservedOrder: React.FC<ReservedOrderProps> = ({ eventId, productList, orderLineItems, paths, order, openModal }) => {
  const registryItemId = orderLineItems?.filter(lineItem => lineItem.registryItem)[0].registryItem?.id;
  const product = productList.find(product => product.id === registryItemId);

  const orderItem = orderLineItems.find(lineItem => {
    return lineItem.quantity;
  });

  if (!product) {
    return null;
  }
  return <ReservedItem order={order} paths={paths} product={product} itemQuantity={orderItem ? orderItem.quantity : 1} eventId={eventId} openModal={openModal} />;
};

export type ProductList = Readonly<{
  counts: {
    everything: number;
    favorites: number;
    stillNeeded: number;
    purchased: number;
    reserved: number;
    over100: number;
    under100: number;
    available: number;
    deliverable: number;
  };
  products: readonly CookedProduct[];
}>;
interface ShoppingCartInnerProps
  extends Readonly<{
    eventId: string;
    shippingAddress: ShippingAddressFragment | null;
    isShoppingPage?: boolean;
    helpDialogOpen: boolean;
    productList: ProductList;
    reservedOrderList: RegistryOrderFragment[];
    purchasedOrderList: RegistryOrderFragment[];
    orderCount: number;
    cartOrderCount: number;
    cartTotal: string;
    paths: ReturnType<typeof useRoutePaths>;
    onClose?: (args: OnCloseArg) => void;
    showHelpDialog: () => void;
    handleDialogClose: () => void;
  }> {}

export const ShoppingCartInner: React.FC<ShoppingCartInnerProps> = ({
  eventId,
  shippingAddress,
  isShoppingPage,
  helpDialogOpen,
  productList,
  reservedOrderList,
  purchasedOrderList,
  orderCount,
  cartOrderCount,
  cartTotal,
  paths,
  onClose,
  showHelpDialog,
  handleDialogClose
}) => {
  const { t } = useTranslation('sharedRegistry');
  const { openModal } = usePurchaseConfirmationProvider();
  const { openCheckoutDialog } = useCheckoutDialogContext();
  const { updateDataProvider } = useGuestRegistryState();

  const { helpText, purchaseHistoryTitle } = t('shoppingCart');
  const enableCashFundCheckoutV2 = useFeatureValue('enableCashFundCheckoutV2');
  const enableAffiliateCheckoutV2 = useFeatureValue('enableAffiliateCheckoutV2');

  const purchasedOrderListSorted = useMemo(() => {
    return purchasedOrderList.sort((a, b) => b.orderedAt.milliseconds - a.orderedAt.milliseconds);
  }, [purchasedOrderList]);

  return (
    <StyledBox width={isShoppingPage ? null : { _: '100%', xs: pxToRem(374) }}>
      {cartOrderCount > 0 ? (
        <>
          <Header giftCount={orderCount} cartTotal={cartTotal} onClose={onClose} />
          {reservedOrderList?.length
            ? reservedOrderList.map(order => {
                return order ? (
                  <ReservedOrder
                    paths={paths}
                    orderLineItems={order.lineItems}
                    key={order.id}
                    productList={productList.products}
                    eventId={eventId}
                    order={order}
                    openModal={(productId: string, modalStateToBeOpened?: ModalStates) => {
                      // Manual added items and Transferred registry items are not supported yet on the new flow, so here we avoid that case
                      if (enableCashFundCheckoutV2) {
                        const platformType = productList.products.find(product => product.id === productId)?.donationFund?.fund.platform?.type;
                        const isDonation =
                          platformType === DonationFundPlatformTypeEnum.paypal ||
                          platformType === DonationFundPlatformTypeEnum.other ||
                          platformType === DonationFundPlatformTypeEnum.venmo ||
                          platformType === DonationFundPlatformTypeEnum.cashapp;
                        if (isDonation) {
                          openCheckoutDialog({ registryItemId: productId, currentOrderId: order.id });
                          return;
                        }
                      }

                      if (enableAffiliateCheckoutV2) {
                        updateDataProvider({ shippingAddress, productId: productId });
                        openCheckoutDialog({ isAffiliate: true, registryItemId: productId, currentOrderId: order.id });
                        return;
                      }

                      openModal({
                        orderId: order.id,
                        productId,
                        modalStateToBeOpened
                      });
                    }}
                  />
                ) : null;
              })
            : null}
          {purchasedOrderListSorted?.length ? (
            <StylePurchaseBox paddingX={5} paddingTop={6}>
              <TextV2 typographyVariant="hed2" display="flex" alignItems="center" justifyContent="center">
                {purchaseHistoryTitle()}
              </TextV2>
              {purchasedOrderListSorted.map(order => {
                return order ? (
                  <PurchaseHistory
                    paths={paths}
                    orderId={order.id}
                    isDropship={order.isDropship}
                    orderLineItems={order.lineItems}
                    key={order.id}
                    productList={productList.products}
                    eventId={eventId}
                    note={order.note}
                    fulfillment={order.fulfillment}
                  />
                ) : null;
              })}
            </StylePurchaseBox>
          ) : null}
          <AddressCollapsible shippingAddress={shippingAddress} />
          <LinkV2 onClick={showHelpDialog} textDecoration="none">
            <HelpText typographyVariant="body1" display="flex" marginY={'64px'} alignItems="center" justifyContent="center">
              <HelpIcon />
              {helpText()}
            </HelpText>
          </LinkV2>
          <HelpDialog isOpen={helpDialogOpen} onClose={handleDialogClose} />
        </>
      ) : (
        <TempSpinner />
      )}
    </StyledBox>
  );
};

interface ShoppingCartProps
  extends Readonly<{
    eventId: string;
    isShoppingPage?: boolean;
    orders: RegistryOrderList;
    registry: RegistryList;
    shippingAddress: ShippingAddressFragment | null;
    registryCurrencyCode?: string;
  }> {}

export const ShoppingCart: React.FC<ShoppingCartProps> = ({ eventId, isShoppingPage, registry, shippingAddress, orders, registryCurrencyCode }) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const { t } = useTranslation('sharedRegistry');
  const { viewGift } = t('shoppingCart');

  const paths = useRoutePaths();
  const {
    state: { isShoppingCartOpen },
    mutators: { openShoppingCart, closeShoppingCart }
  } = useShoppingCart();

  const history = useHistory();
  const previousUrl = history.location.pathname;

  const {
    helpDialogOpen,
    productList,
    reservedOrderList,
    purchasedOrderList,
    orderCount,
    cartOrderCount,
    cartTotal,
    showHelpDialog,
    handleDialogClose
  } = useShoppingCartController({
    registries: registry,
    orders,
    registryCurrencyCode
  });

  const { shoppingCartButtonClick } = useShoppingCartTelemetry();

  const showShoppingCartDialog = useCallback(() => {
    shoppingCartButtonClick({ eventId, device: isMobileOrTablet ? 'mobileOrTablet' : 'desktop' });
    withWindow(window => window.history.pushState(null, 'product', window.location.href));
    openShoppingCart();
  }, [isMobileOrTablet, openShoppingCart, eventId, shoppingCartButtonClick]);

  useMount(() => {
    withWindow(({ location }) => {
      // eslint-disable-next-line compat/compat
      const params = new URLSearchParams(location.search);
      if (params.get('shopping-cart') === 'active' && isMobileOrTablet) {
        showShoppingCartDialog();
      }
    });
  });

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        withWindow(window => window.history.replaceState(null, 'product', previousUrl));
        closeShoppingCart();
      }
    });
  }, [history, closeShoppingCart, previousUrl]);

  return (
    <>
      <ShoppingCartDialog
        isOpen={isShoppingCartOpen}
        eventId={eventId}
        shippingAddress={shippingAddress}
        isShoppingPage={isShoppingPage}
        helpDialogOpen={helpDialogOpen}
        productList={productList}
        reservedOrderList={reservedOrderList}
        purchasedOrderList={purchasedOrderList}
        orderCount={orderCount}
        cartOrderCount={cartOrderCount}
        cartTotal={cartTotal}
        paths={paths}
        onClose={closeShoppingCart}
        showHelpDialog={showHelpDialog}
        handleDialogClose={handleDialogClose}
      />
      {isMobileOrTablet && !isShoppingPage ? (
        <StyledBoxMob>
          <StyledCheckoutButton intent="primary" variant="solid" onClick={showShoppingCartDialog}>
            <Box alignItems="center" display="inline-flex">
              <Box display="flex">
                <CartIcon />
                {orderCount > 0 && (
                  <TextV2 typographyVariant="hed2" color={'white'}>
                    {orderCount}
                  </TextV2>
                )}
              </Box>
              <Box display="flex">
                <TextV2 typographyVariant="hed2" color={'white'} marginLeft={6}>
                  {viewGift()}
                </TextV2>
              </Box>
            </Box>
          </StyledCheckoutButton>
        </StyledBoxMob>
      ) : (
        <ShoppingCartOpenButton onClick={showShoppingCartDialog} orderCount={orderCount} />
      )}
    </>
  );
};
