import React, { useMemo } from 'react';
import { Flex, CloseButton } from '@withjoy/joykit';
import { CartIcon } from '@assets/icons';
import { useTranslation } from '@shared/core';

import { CartSections } from '@apps/registry/common/components/ShoppingCart';
import { useShoppingCart } from '@apps/registry/common/components/ShoppingCart/state';

import { StyledHeaderBox, StyleText, StyledNotification, StyledCloseButtonBox, StyledPurchaseText, styles } from './HeaderV2.styles';

export type HeaderProps = Readonly<{
  cartOrderCount: number;
  displayNotification?: boolean;
}>;

export const HeaderV2: React.FC<HeaderProps> = ({ cartOrderCount, displayNotification }) => {
  const { t2 } = useTranslation('sharedRegistry');
  const {
    state: { currentSection: activeSection },
    mutators: { updateCurrentSection, closeShoppingCart }
  } = useShoppingCart();
  const { cartTitle, historyTitle } = t2('shoppingCart', 'cartStep');
  const displayPurchaseTab = useMemo(() => cartOrderCount > 0, [cartOrderCount]);

  return (
    <StyledHeaderBox isBorderBottom={activeSection !== CartSections.HISTORY && !displayPurchaseTab}>
      <Flex justifyContent="space-between">
        <Flex justifyContent="space-evenly" width="100%">
          <Flex width="100%">
            <StyleText typographyVariant="hed5" onClick={() => updateCurrentSection(CartSections.CART)} isBorderRight={displayPurchaseTab} width="100%">
              <CartIcon /> {cartTitle}
            </StyleText>
          </Flex>
          {displayPurchaseTab && (
            <Flex
              backgroundColor={activeSection === CartSections.HISTORY ? 'mono2' : 'white'}
              alignItems="center"
              justifyContent="center"
              width="100%"
              borderBottom={activeSection !== CartSections.HISTORY ? '1px solid' : 'none'}
              borderBottomColor="mono3"
            >
              <StyledPurchaseText typographyVariant="hed5" onClick={() => updateCurrentSection(CartSections.HISTORY)}>
                {historyTitle}
              </StyledPurchaseText>
              {displayNotification && <StyledNotification __css={styles.notificationStyles} />}
            </Flex>
          )}
        </Flex>
        {!!closeShoppingCart && (
          <StyledCloseButtonBox
            isBorderLeft={displayPurchaseTab}
            borderBottom={activeSection !== CartSections.HISTORY && displayPurchaseTab ? '1px solid' : 'none'}
            borderBottomColor="mono3"
          >
            <CloseButton
              height="100%"
              iconSize={32}
              onClick={ev => {
                ev.stopPropagation();
                closeShoppingCart();
              }}
            />
          </StyledCloseButtonBox>
        )}
      </Flex>
    </StyledHeaderBox>
  );
};
