import { Category } from '@graphql/generated';
import { OverrideTypography } from './GuestSiteTypographyOverride.types';

export const hed2ToSubHeadingOverride: OverrideTypography = [{ variant: 'hed2', category: Category.SUB_HEADING }];
export const hed3ToSubHeadingOverride: OverrideTypography = [{ variant: 'hed3', category: Category.SUB_HEADING }];
export const hed4ToHeadingOverride: OverrideTypography = [{ variant: 'hed4', category: Category.HEADING }];
export const hed4ToSubHeadingOverride: OverrideTypography = [{ variant: 'hed4', category: Category.SUB_HEADING }];
export const hed5ToHeadingOverride: OverrideTypography = [{ variant: 'hed5', category: Category.HEADING }];

export const body1ToNavigationOverride: OverrideTypography = [{ variant: 'body1', category: Category.NAVIGATION }];
export const body1ToParagraphOverride: OverrideTypography = [{ variant: 'body1', category: Category.PARAGRAPH }];
export const body2ToNavigationOverride: OverrideTypography = [{ variant: 'body2', category: Category.NAVIGATION }];
export const body2ToSubHeadingOverride: OverrideTypography = [{ variant: 'body2', category: Category.SUB_HEADING }];
export const body2ToParagraphOverride: OverrideTypography = [{ variant: 'body2', category: Category.PARAGRAPH }];
export const body2ToButtonsOverride: OverrideTypography = [{ variant: 'body2', category: Category.BUTTONS }];
export const body3ToParagraphOverride: OverrideTypography = [{ variant: 'body3', category: Category.PARAGRAPH }];
export const body3ToSubHeadingOverride: OverrideTypography = [{ variant: 'body3', category: Category.SUB_HEADING }];
export const body3ToButtonsOverride: OverrideTypography = [{ variant: 'body3', category: Category.BUTTONS }];
export const body4ToSubHeadingOverride: OverrideTypography = [{ variant: 'body4', category: Category.SUB_HEADING }];
export const body4ToParagraphOverride: OverrideTypography = [{ variant: 'body4', category: Category.PARAGRAPH }];

export const display1ToLocationOverride: OverrideTypography = [{ variant: 'display1', category: Category.LOCATION }];
export const display2ToDateOverride: OverrideTypography = [{ variant: 'display2', category: Category.DATE }];
export const display3ToEventTitleOverride: OverrideTypography = [{ variant: 'display3', category: Category.EVENT_TITLE }];
export const display4ToHeadingOverride: OverrideTypography = [{ variant: 'display4', category: Category.HEADING }];
export const display5ToEventTitleOverride: OverrideTypography = [{ variant: 'display5', category: Category.EVENT_TITLE }];
export const display8ToEventTitleOverride: OverrideTypography = [{ variant: 'display8', category: Category.EVENT_TITLE }];

export const label3ToParagraphOverride: OverrideTypography = [{ variant: 'label3', category: Category.PARAGRAPH }];
export const label2ToParagraphOverride: OverrideTypography = [{ variant: 'label2', category: Category.PARAGRAPH }];
export const label4ToParagraphOverride: OverrideTypography = [{ variant: 'label4', category: Category.PARAGRAPH }];
export const label4ToNavigationOverride: OverrideTypography = [{ variant: 'label4', category: Category.NAVIGATION }];
export const label6ToSubHeadingOverride: OverrideTypography = [{ variant: 'label6', category: Category.SUB_HEADING }];
export const label6ToParagraphOverride: OverrideTypography = [{ variant: 'label6', category: Category.PARAGRAPH }];

export const button1ToButtonsOverride: OverrideTypography = [{ variant: 'button1', category: Category.BUTTONS }];
export const button1ToParagraphOverride: OverrideTypography = [{ variant: 'button1', category: Category.PARAGRAPH }];
export const button2ToButtonsOverride: OverrideTypography = [{ variant: 'button2', category: Category.BUTTONS }];
