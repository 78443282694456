import { Box, TextV2, ButtonV2, ButtonV2StyleProps, StyleSystemProps, styled } from '@withjoy/joykit';
import { ReactComponent as Hamburger } from '@assets/icons/derburger.svg';
import { pxToRem } from '@withjoy/joykit/theme';
import { animationTransitionExt } from '@shared/utils/animationTransition';

export const StyledMenuButtonTitle = styled(TextV2)``;
export const StyledMenuButtonContent = styled(Box)``;

export const StyledMenuButton = styled(ButtonV2)<{ isPreviewing: boolean }>`
  padding: 0 24px;
  border-radius: 100px !important;
  pointer-events: all;
  &::before {
    content: '';
    position: absolute;
    inset: 0 0 0 0;
    background: rgba(255, 255, 255, 0.72);
    backdrop-filter: saturate(180%) blur(20px);
  }
  &:hover::before {
    content: '';
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.8);
    background: inherit;
  }
  &:active::before {
    content: '';
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.8);
    background: inherit;
  }
`;

export const StyledHamburger = styled(Hamburger)`
  margin: 0px 16px 0px 0px;
`;

const menuButtonContent: StyleSystemProps = {
  display: 'flex',
  zIndex: 'overlay',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center'
};

const menuButton = (isCustomPage?: boolean): ButtonV2StyleProps => ({
  position: 'fixed',
  zIndex: 'sticky',
  top: {
    _: isCustomPage ? pxToRem(14) : 6,
    sm2: isCustomPage ? pxToRem(14) : 6
  },
  transition: animationTransitionExt({ property: 'top', duration: '250ms', timingFunction: 'ease-in-out' }),
  left: [6],
  background: 'inherit !important',
  overflow: 'hidden',
  color: 'black'
});

export const styles = {
  content: menuButtonContent,
  button: menuButton
};
