import React from 'react';
import { useTranslation } from '@shared/core';

import { StyledBox, styles } from './EmptyCart.styles';
import { ReactComponent as EmptyCartIcon } from './empty-cart.svg';
import { Box } from '@withjoy/joykit';

export const EmptyCart = () => {
  const { t } = useTranslation('sharedRegistry');
  const { cartEmptyMessage } = t('shoppingCart');
  return (
    <StyledBox __css={styles.box}>
      <Box marginBottom={16}>
        <EmptyCartIcon />
      </Box>
      {cartEmptyMessage()}
    </StyledBox>
  );
};
