import { GetCatalogProductDetailsQuery, useGetCatalogProductDetailsLazyQuery as useBaseLazyQuery, useGetCatalogProductDetailsQuery as useBaseQuery } from '@graphql/generated';

export type UseGetCatalogProductDetailsQueryArgs = {
  catalogItemId: Maybe<string>;
  options?: Parameters<typeof useBaseQuery>[0];
};

const mergeProductDataQueryResults = (data?: GetCatalogProductDetailsQuery) => {
  if (!data?.productCatalogItemById) {
    return;
  }
  return {
    ...data.productCatalogItemById,
    ...data.productInventoryByCatalogItemId
  };
};

export type MergedetCatalogProductDetails = ReturnType<typeof mergeProductDataQueryResults>;

export const useGetPdpDataByProductIdQuery = (args: UseGetCatalogProductDetailsQueryArgs) => {
  const { catalogItemId } = args;
  const { variables, ...restOptions } = args.options || {};

  const { data, error, loading } = useBaseQuery({
    batchMode: 'fast',
    errorPolicy: 'ignore',
    // To avoid apollo cache entity conflicts, don't store the result of this query.
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'no-cache',
    skip: !catalogItemId,
    variables: {
      // WARNING: Do not remove default variables below because they are mandatory
      id: catalogItemId!,
      withPdpSections: true,
      withProductRating: false,
      withInventory: false,
      ...variables
    },
    ...restOptions
  });

  return {
    error,
    loading,
    product: mergeProductDataQueryResults(data)
  };
};

export type UseGetCatalogProductDetailsLazyQueryArgs = {
  catalogItemId: Maybe<string>;
  options?: Parameters<typeof useBaseQuery>[0];
};

export const useGetPdpDataByProductIdLazyQuery = (args: UseGetCatalogProductDetailsLazyQueryArgs) => {
  const { catalogItemId } = args;
  const [sendRequest, { data, error, loading }] = useBaseLazyQuery({
    batchMode: 'fast',
    // To avoid apollo cache entity conflicts, don't store the result of this query.
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'no-cache',
    skip: !catalogItemId,
    ...args.options
  });

  return {
    sendRequest,
    error,
    loading,
    product: mergeProductDataQueryResults(data)
  };
};
