import { CustomItemDataType, DonationFundPlatformTypeEnum } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { useCallback } from 'react';
import { PLATFORM_LABELS } from '../../CheckoutDialog.constants';
import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useCheckoutInitDataContext } from '../../hooks/useCheckoutInitData';
import { useCheckoutTelemetryData, useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { withWindow } from '@shared/utils/withWindow';
import { runIfFn } from '@shared/utils/functions';
import { addAction } from '@shared/utils/logger';
import { useShoppingCartTelemetry } from '@apps/registry/common/components/ShoppingCart/ShoppingCart.telemetry';

const VENMO_PAYMENT_URL = 'https://account.venmo.com/payment-link';

function buildVenmoPaymentLink(username: string, amount: string, note?: string) {
  const url = new URL(VENMO_PAYMENT_URL);

  const params = new URLSearchParams({
    txn: 'pay',
    recipients: username,
    amount
  });

  if (note) {
    params.append('note', note);
  }

  url.search = params.toString();

  return url.toString();
}

export const useExternalCheckoutReviewController = () => {
  const {
    formValues,
    initiateCancelProcess,
    toNext,
    context: { isAffiliate, isGroupGifting }
  } = useCheckoutFunnel(({ formValues, initiateCancelProcess, toNext, context }) => [formValues, initiateCancelProcess, toNext, context.isAffiliate, context.isGroupGifting]);
  const { registryItem } = useCheckoutInitDataContext();
  const { buyRegistryItem } = useShoppingCartTelemetry();
  const { t } = useTranslation('guestRegistry');
  const { product, productImageSrc, siteName, currency } = useGetProductData('externalCheckoutReview');

  const translations = t('checkoutDialog', 'screens', 'externalCheckoutReview');
  const isCharity = registryItem?.donationFund?.fundType === CustomItemDataType.charity;
  const charityTitle = registryItem?.productData.title;
  const platformLabel = isAffiliate ? siteName : isCharity ? charityTitle : formValues?.paymentMethod ? PLATFORM_LABELS[formValues?.paymentMethod] : '';
  const isCashFundType = registryItem?.donationFund?.fundType === 'cash';
  const { registryItemId, reservedQty, priceValueInMinorUnits, typeOfItem, priceCurrencyCode, productTitle } = useCheckoutTelemetryData();

  const handleRedirectToRetailer = useCallback(() => {
    const username = registryItem?.donationFund?.platform?.username || '';
    const amount = formValues.amount?.toString() || '';
    const note = formValues.note;
    const url =
      registryItem?.donationFund?.platform?.type === DonationFundPlatformTypeEnum.venmo
        ? buildVenmoPaymentLink(username, amount, note)
        : registryItem?.productData?.checkoutUrl || registryItem?.donationFund?.platform?.link || '';
    withWindow(window => {
      window.open(url, '_blank');
    });
    runIfFn(toNext);
  }, [registryItem, toNext, formValues.amount, formValues.note]);

  const handleGoToButton = useCallback(() => {
    const buyRegistryItemData = {
      productTitle,
      registryItemId,
      reservedQty,
      priceValueInMinorUnits,
      priceCurrencyCode,
      itemType: typeOfItem,
      buttonLabel: translations.goToButton({ platform: platformLabel }),
      redirectUrl: registryItem?.productData.externalUrl,
      action: 'redirect',
      method: 'purchase',
      isGroupGiftingEnabled: isGroupGifting
    };
    buyRegistryItem(buyRegistryItemData);
    addAction('buyRegistryItem', buyRegistryItemData);
    handleRedirectToRetailer();
  }, [
    buyRegistryItem,
    isGroupGifting,
    platformLabel,
    priceCurrencyCode,
    priceValueInMinorUnits,
    productTitle,
    handleRedirectToRetailer,
    registryItem?.productData.externalUrl,
    registryItemId,
    reservedQty,
    translations,
    typeOfItem
  ]);

  return {
    formValues,
    initiateCancelProcess,
    redirectToExternalGateway: handleGoToButton,
    translations,
    platformLabel,
    isCharity,
    isAffiliate,
    product,
    currency,
    productImageSrc,
    isCashFundType
  };
};
