import { useTranslation } from '@shared/core';
import { useTranslation as useReactTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCreateSingleRegistryItemOrderMutation } from '@graphql/generated';
import { OptionType } from '@withjoy/joykit';
import { removeItemFromPurchaseClickHistory } from '@apps/registry/common/components/ShoppingCart/utils/trackPurchaseClicks';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { CreateOrderAndPurchaseProps } from './CreateOrderAndPurchase';
import { useShoppingCartTelemetry } from '@apps/registry/common/components/ShoppingCart/ShoppingCart.telemetry';

interface PurchaseDialogFields {
  email: string;
  name: string;
  quantity: number;
}

export const useCreateOrderAndPurchaseController = ({
  product,
  handlePurchaseSuccess,
  eventId
}: Pick<CreateOrderAndPurchaseProps, 'product' | 'handlePurchaseSuccess' | 'eventId'>) => {
  const { t } = useTranslation('guestRegistry');
  const { t: translateForm } = useReactTranslation('generalFormValidation');
  const { requiredNameWarning, requiredEmailWarning, validEmailWarning } = t('purchaseDialog');
  const { updateDataProvider } = useGuestRegistryState();
  const { shoppingCartMakePurchaseClick } = useShoppingCartTelemetry();

  const onCreateAndPurchaseOrderError = () => {
    updateDataProvider({
      type: 'error',
      order: null,
      guest: null,
      quantity: 0
    });
  };

  const [createRegistryOrder, { loading }] = useCreateSingleRegistryItemOrderMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders'],
    onError: onCreateAndPurchaseOrderError
  });

  const createOrderAndPurchase = async (values: PurchaseDialogFields) => {
    if (product?.id) {
      const { data } = await createRegistryOrder({
        variables: {
          payload: {
            registryItemId: product.id,
            name: values.name,
            email: values.email,
            quantity: values.quantity,
            isPurchased: true
          }
        }
      });
      const orderId = data?.createSingleRegistryItemOrder.model?.id;
      if (orderId) {
        updateDataProvider({
          type: 'purchased',
          productId: product.id,
          order: {
            orderId: orderId
          },
          guest: {
            name: values.name,
            email: values.email
          },
          quantity: values.quantity
        });

        const telemArgs = {
          eventId,
          productId: product?.id,
          registryItemId: product?.id,
          reservedQty: values.quantity * 100,
          priceValueInMinorUnits: product?.productData?.price?.valueInMinorUnits,
          priceCurrencyCode: product?.productData?.price?.currency?.code,
          destinationUrl: product?.productData?.checkoutUrl,
          typeOfItem: product?.productData?.legacyType,
          buttonLabel: 'Purchase Confirmation screen',
          productTitle: product?.productData?.title,
          giftGiverName: values.name,
          giftGiverEmail: values.email,
          isGroupGiftingEnabled: !!product.isGroupGiftingEnabled
        };
        shoppingCartMakePurchaseClick(telemArgs);
      }
    }
  };

  const handleSubmit = async (values: PurchaseDialogFields) => {
    await createOrderAndPurchase(values);
    if (product?.id) {
      removeItemFromPurchaseClickHistory({ reservedOrderId: null, productId: product.id });
    }
    handlePurchaseSuccess();
  };

  const stillNeededOptions: OptionType[] =
    product && product.stillNeeded >= 1 ? Array.from(Array(product.stillNeeded).keys()).map(i => ({ label: `${i + 1}`, value: `${i + 1}` })) : [];

  const formik = useFormik<PurchaseDialogFields>({
    initialValues: {
      quantity: 1,
      name: '',
      email: ''
    },
    validationSchema: Yup.object<PurchaseDialogFields>({
      name: Yup.string().required(requiredNameWarning()),
      quantity: Yup.number().min(1).required(translateForm('required')),
      email: Yup.string().email(validEmailWarning()).required(requiredEmailWarning())
    }),
    onSubmit: handleSubmit
  });

  const handleOnQtyChange = useCallback(
    (option: OptionType | null) => {
      formik.setFieldValue('quantity', Number.parseInt(option?.value || '1'));
    },
    [formik]
  );

  const nameInputErrorValue = useMemo(() => {
    return formik.errors.name && formik.touched.name ? formik.errors.name : undefined;
  }, [formik.errors.name, formik.touched.name]);
  const nameInputIsInvalid = useMemo(() => {
    return !!formik.getFieldMeta('name').error && !!formik.getFieldMeta('name').touched;
  }, [formik]);

  const emailInputErrorValue = useMemo(() => {
    return formik.errors.email && formik.touched.email ? formik.errors.email : undefined;
  }, [formik.errors.email, formik.touched.email]);
  const emailInputIsInvalid = useMemo(() => {
    return !!formik.getFieldMeta('email').error && !!formik.getFieldMeta('email').touched;
  }, [formik]);

  return {
    formik,
    stillNeededOptions,
    loading,
    nameInputErrorValue,
    nameInputIsInvalid,
    emailInputErrorValue,
    emailInputIsInvalid,
    handleOnQtyChange
  };
};
