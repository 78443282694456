import React, { useMemo, useState } from 'react';
import { ButtonV2, Flex, IconV2, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

import { useShoppingCart } from '@apps/registry/common/components/ShoppingCart/state';
import { CartCheckoutSteps, CartSections, InCartProduct } from '@apps/registry/common/components/ShoppingCart';

import {
  CartFooterAtomsContainer,
  ShippingAddressContainer,
  ShippingAddressText,
  styles,
  SubtotalTextContainer,
  TotalTextContainer,
  CartFooterAtomsWrapper
} from './CartFooterAtoms.styles';
import { Shipping } from '../MetadataAtoms';
import { CartFooterSubmitButton } from './CartFooterSumbitButton';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { SummaryItemListContainer } from '../SummaryItemList';
import { useGiftWrapECardPrice } from '@shared/components';

type Props = {
  subtotal?: string;
  total?: string;
  inCartProductList?: Array<InCartProduct>;
  inCartProductTotal?: number;
  deliveryTotal?: number;
  salesTaxTotal?: number;
  registryCurrencyCode?: string;
  saveData?: () => void;
  hasECard?: boolean;
  disableNextStep?: boolean;
};

export const CartFooterAtoms = ({ subtotal, total, inCartProductList, inCartProductTotal, registryCurrencyCode, hasECard, saveData, disableNextStep }: Props) => {
  const { t } = useTranslation('sharedRegistry');
  const { cartFooterAddressText, subtotal: subtotalText, modifyButton, total: totalText, closeOrderSummary } = t('shoppingCart');
  const { shipping, freeShipping } = t('shoppingCart', 'shippingAddressStep');
  const isMobileOrTablet = useIsMobileOrTablet();
  const registryGiftWrapEcardPrice = useGiftWrapECardPrice();

  const {
    state: { currentCheckoutStep, shippingAddress, shippingFee },
    mutators: { updateCurrentSection }
  } = useShoppingCart();
  const getShippingMethodText = () => {
    if (shippingFee && shippingFee === '0.00') {
      return freeShipping();
    }
    return shipping();
  };
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  const totalWithECard = useMemo(() => {
    if (!total || !hasECard) {
      return total;
    }
    const totalParsed = parseFloat(total.substring(1));
    return `$${totalParsed + registryGiftWrapEcardPrice}`;
  }, [hasECard, registryGiftWrapEcardPrice, total]);

  return (
    <CartFooterAtomsContainer __css={styles.cartFooterAtomsContainerStyles(isSummaryOpen)}>
      {isMobileOrTablet && currentCheckoutStep !== CartCheckoutSteps.Cart && inCartProductList && inCartProductTotal && (
        <SummaryItemListContainer
          inCartProductList={inCartProductList}
          inCartProductTotal={inCartProductTotal}
          registryCurrencyCode={registryCurrencyCode}
          isOpen={isSummaryOpen}
          setIsOpen={setIsSummaryOpen}
          hasECard={hasECard}
        />
      )}
      <CartFooterAtomsWrapper __css={styles.cartFooterAtomsWrapperStyles(isMobileOrTablet)}>
        {currentCheckoutStep === CartCheckoutSteps.Cart && (
          <ShippingAddressContainer __css={styles.shippingAddressContainerStyles}>
            <Flex alignItems="center">
              <IconV2 size="sm" marginRight={3}>
                <Shipping />
              </IconV2>
              <ShippingAddressText __css={styles.shippingAddressTextStyles}>
                {cartFooterAddressText({ shippingMethod: getShippingMethodText(), addressName: shippingAddress?.name })}
              </ShippingAddressText>
            </Flex>
            <ShippingAddressText __css={styles.shippingAddressButtonStyles} onClick={() => updateCurrentSection(CartSections.ADDRESS)}>
              {modifyButton()}
            </ShippingAddressText>
          </ShippingAddressContainer>
        )}
        {subtotal && (
          <SubtotalTextContainer __css={styles.subtotalTextContainerStyles}>
            <TextV2 typographyVariant="hed1">{subtotalText()}</TextV2>
            <TextV2 typographyVariant="hed1">{subtotal}</TextV2>
          </SubtotalTextContainer>
        )}
        {total && (
          <TotalTextContainer __css={styles.totalTextContainerStyles}>
            <TextV2 typographyVariant="hed4">{totalText()}</TextV2>
            <TextV2 typographyVariant="hed4">{totalWithECard}</TextV2>
          </TotalTextContainer>
        )}
        {isSummaryOpen ? (
          <ButtonV2 intent="neutral" marginTop={6} fullWidth onClick={() => setIsSummaryOpen(false)}>
            {closeOrderSummary()}
          </ButtonV2>
        ) : (
          <CartFooterSubmitButton saveData={saveData} disabled={disableNextStep} />
        )}
      </CartFooterAtomsWrapper>
    </CartFooterAtomsContainer>
  );
};
