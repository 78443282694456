import React from 'react';
import { useTranslation } from '@shared/core';
import { Box, ButtonV2, Flex, SpacingStack, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

import { MetadataBase } from '../MetadaBase';
import { Shipping } from '../icons';
import { EditGiftMessage, StyledTextArea } from './EditGiftMessage.styles';
import { useGiftNoteController } from '../../GiftNote/GiftNote.controller';

type Props = {
  giftNote?: string;
  handleOnClickSave: () => void;
  handleOnClickEdit: () => void;
  handleCancel: () => void;
  isEditMode: boolean;
  loading: boolean;
  formik?: ReturnType<typeof useGiftNoteController>['formik'];
};

export const GiftNoteMetadata = ({ giftNote, handleOnClickSave, handleOnClickEdit, handleCancel, isEditMode, loading, formik }: Props) => {
  const { t2 } = useTranslation('sharedRegistry');
  const { editGiftMessage } = t2('shoppingCart');
  const { giftMessage, leaveNoteForCouple, saveGiftNoteButton, cancelButton } = t2('shoppingCart', 'metadataAtoms');

  return isEditMode && formik ? (
    <Box maxWidth={pxToRem(316)}>
      <TextV2 typographyVariant="label3" marginBottom={3} textAlign="left">
        {giftMessage}
      </TextV2>
      <StyledTextArea {...formik.getFieldProps('note')} placeholder={leaveNoteForCouple} />

      <SpacingStack spacing={5} marginTop={7}>
        <ButtonV2 onClick={handleOnClickSave} intent="neutral" loading={loading}>
          {saveGiftNoteButton}
        </ButtonV2>
        <ButtonV2 variant="ghost" color="black" intent="neutral" onClick={handleCancel}>
          {cancelButton}
        </ButtonV2>
      </SpacingStack>
    </Box>
  ) : (
    <MetadataBase
      icon={<Shipping />}
      text={giftMessage}
      content={
        <Box rowGap={pxToRem(6)} maxWidth={pxToRem(316)}>
          <Flex>
            <EditGiftMessage>{giftNote}</EditGiftMessage>
          </Flex>
          <Flex justifyContent="flex-end" cursor="pointer">
            <TextV2 typographyVariant="body1" color="mono12" textDecoration="underline" onClick={handleOnClickEdit}>
              {editGiftMessage}
            </TextV2>
          </Flex>
        </Box>
      }
    />
  );
};
