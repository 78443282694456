import { useAnnotateRegistryOrderMutation } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useShoppingCartTelemetry } from '../../ShoppingCart.telemetry';

interface EditNoteFields {
  note: Maybe<string>;
}

export const useGiftNoteController = (note: Maybe<string>, orderId: string) => {
  const [annotateRegistryOrder, { loading }] = useAnnotateRegistryOrderMutation();
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const { t } = useTranslation('sharedRegistry');
  const { messageUpdateSuccess, messageUpdateError } = t('shoppingCart');

  const [giftNoteError, setGiftNoteError] = useState<boolean>(false);
  const [giftNoteNotificationIsVisible, setGiftNoteNotificationIsVisible] = useState<boolean>(false);
  const [prevGiftNote, setPrevGiftNote] = useState<Maybe<string>>(note);

  const showNotification = useCallback(() => {
    setGiftNoteNotificationIsVisible(true);
    withWindow(global => {
      global.setTimeout(() => {
        setGiftNoteNotificationIsVisible(false);
      }, 1500);
    });
  }, []);

  const { giftMessageSaveClicked } = useShoppingCartTelemetry();
  const formik = useFormik<EditNoteFields>({
    initialValues: {
      note
    },
    validationSchema: Yup.object<EditNoteFields>({
      note: Yup.string()
    }),
    onSubmit: () => {}
  });

  const handleOnClickEdit = () => {
    setIsInEditMode(true);
  };

  const handleOnClickSave = async () => {
    giftMessageSaveClicked();
    const noteValue = formik.values.note;
    // handle the guest clicking add note and then saving without adding a note (aka canceling)
    if (!note && !noteValue) {
      setIsInEditMode(false);
    } else {
      try {
        await annotateRegistryOrder({ variables: { id: orderId, payload: { orderAnnotations: { giftNote: noteValue } } } });
        setGiftNoteError(false);
        setPrevGiftNote(noteValue);
        showNotification();
      } catch {
        formik.setFieldValue('note', note);
        setGiftNoteError(true);
        showNotification();
      }
      setIsInEditMode(false);
    }
  };

  const handleCancel = () => {
    formik.setFieldValue('note', prevGiftNote);
    setIsInEditMode(false);
  };

  return {
    isInEditMode,
    handleOnClickEdit,
    handleOnClickSave,
    handleCancel,
    formik,
    loading,
    giftNoteNotificationText: giftNoteError ? messageUpdateError() : messageUpdateSuccess(),
    giftNoteNotificationIsVisible,
    giftNoteError
  };
};
