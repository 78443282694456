import React, { useCallback, useMemo } from 'react';

import { Flex, TextV2 } from '@withjoy/joykit';
import { useCurrencyFormatter } from '@shared/utils/currency';

import { isPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { InCartProduct } from '@apps/registry/common/components/ShoppingCart';
import { useShoppingCart } from '@apps/registry/common/components/ShoppingCart/state';

import { ItemBase } from '../../../../../../../components';
import { ItemCounter } from './ItemCounter';
import { StyledFlex } from './InCartItem.styles';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useTranslation } from '@shared/core';
import { useShoppingCartTelemetry } from '@apps/registry/common/components/ShoppingCart/ShoppingCart.telemetry';

type InCartItemProps = Readonly<{
  inCartProduct: InCartProduct;
}>;

export const InCartItem: React.FC<InCartItemProps> = ({ inCartProduct }) => {
  const { shoppingCartEditCartClick, shoppingCartRemoveFromCartClick } = useShoppingCartTelemetry();
  const { eventInfo } = useEventInfo();
  const { t: translations } = useTranslation('catalogRegistry');
  const { message } = translations('salesPrice');

  const { formatCurrency } = useCurrencyFormatter();

  const { product, quantity } = inCartProduct;
  const totalPrice = useMemo(() => {
    if (isPricedProduct(product)) {
      const totalCharmPriceString = `${product.numberPrice * quantity}`;

      return formatCurrency({
        priceFloatingPointDecimalString: totalCharmPriceString,
        priceCurrencyCode: product.currencyCode,
        formatForm: 'short'
      });
    }

    return '';
  }, [product, quantity, formatCurrency]);

  const price = useMemo(
    () =>
      isPricedProduct(product)
        ? formatCurrency({
            priceFloatingPointDecimalString: product.floatingPointDecimalString,
            priceCurrencyCode: product.currencyCode,
            formatForm: 'short'
          })
        : '',
    [product, formatCurrency]
  );
  const fullPrice = useMemo(
    () =>
      isPricedProduct(product)
        ? formatCurrency({
            priceFloatingPointDecimalString: product.fullPrice || '',
            priceCurrencyCode: product.fullPriceCurrencyCode,
            formatForm: 'short'
          })
        : '',
    [product, formatCurrency]
  );
  const {
    mutators: { removeItem, updateItem }
  } = useShoppingCart();

  const telemArgs = useMemo(
    () => ({
      productTitle: inCartProduct.product.title,
      registryItemId: inCartProduct.registryItemId,
      registryId: inCartProduct.product.registry.id,
      eventId: eventInfo?.eventFirebaseId,
      quantity: 1,
      price: inCartProduct.product.numberPrice
    }),
    [inCartProduct, eventInfo]
  );

  const increaseQuantity = useCallback(() => {
    if (inCartProduct.quantity < inCartProduct.product.stillNeeded) {
      shoppingCartEditCartClick(telemArgs);
      updateItem({ ...inCartProduct, quantity: inCartProduct.quantity + 1 });
    }
  }, [updateItem, shoppingCartEditCartClick, telemArgs, inCartProduct]);

  const decreaseQuantity = useCallback(() => {
    if (inCartProduct.quantity > 1) {
      shoppingCartEditCartClick(telemArgs);
      updateItem({ ...inCartProduct, quantity: inCartProduct.quantity - 1 });
    } else if (inCartProduct.quantity === 1) {
      shoppingCartRemoveFromCartClick(telemArgs);
      removeItem(inCartProduct.registryItemId);
    }
  }, [removeItem, updateItem, shoppingCartEditCartClick, shoppingCartRemoveFromCartClick, telemArgs, inCartProduct]);

  return (
    <ItemBase
      registryItemId={inCartProduct.registryItemId}
      product={inCartProduct.product}
      inStock={inCartProduct.inStock}
      siteName={inCartProduct.product.brand}
      __css={{
        borderBottom: '1px solid',
        borderBottomColor: 'mono3'
      }}
    >
      <Flex gap={2}>
        <TextV2 typographyVariant="label2" fontWeight="semibold" textDecoration={!product.isFullPrice ? 'line-through' : ''}>
          {!product.isFullPrice ? fullPrice : price}
        </TextV2>
        {!product.isFullPrice && (
          <TextV2 typographyVariant="label2" fontWeight="semibold" color="#D80B0B">
            {message()} {price}
          </TextV2>
        )}
      </Flex>

      {inCartProduct.inStock !== false && (
        <StyledFlex>
          <ItemCounter
            increaseCounter={() => increaseQuantity()}
            disableIncrease={quantity === inCartProduct.product.stillNeeded}
            decreaseCounter={() => decreaseQuantity()}
            counter={inCartProduct.quantity}
          />
          <TextV2 textAlign="center" color="mono12" typographyVariant="body1">
            {totalPrice}
          </TextV2>
        </StyledFlex>
      )}
    </ItemBase>
  );
};
